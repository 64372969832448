var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-jobDashboardParameters"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": _vm.$vuetify.breakpoint.lgAndDown ? 12 : 6
    }
  }, [_c('v-expansion-panels', {
    model: {
      value: _vm.expandedParameter,
      callback: function callback($$v) {
        _vm.expandedParameter = $$v;
      },
      expression: "expandedParameter"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', {
    staticClass: "title-panel py-2"
  }, [_c('v-icon', {
    staticClass: "mr-4",
    attrs: {
      "color": "white"
    }
  }, [_vm._v(" fas fa-cog ")]), _c('span', {
    staticClass: "white--text"
  }, [_vm._v(_vm._s(_vm.$t("JobParameters")))])], 1), _c('v-expansion-panel-content', {
    staticClass: "pt-8 pb-8"
  }, [_vm.upToDate && !_vm.jobMetadata.locked && _vm.jobMetadata.status === 'PLANNING' ? _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "px-0 pt-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "text-center pb-4 d-flex align-center justify-center"
  }, [_c('span', [_c('h3', [_vm._v(_vm._s(_vm.$t("Workspace")))])]), _vm.jobMetadata.status !== 'DONE' ? _c('v-tooltip', {
    staticClass: "ml-2",
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          staticClass: "ml-2",
          attrs: {
            "small": ""
          }
        }, 'v-icon', attrs, false), on), [_vm._v(" fas fa-question-circle ")])];
      }
    }], null, false, 2030374295)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("ThisJobUses")))]), _vm.usesJobMaxSize ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("aCustomWorkspace")) + " ")]) : _vm._e(), _vm.usesLineMaxSize ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("lineWorkspace")) + " ")]) : _vm._e(), _vm.usesPrinterMaxSize ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("printerWorkspace")) + " ")]) : _vm._e()]) : _vm._e(), !_vm.editMaxSize && !_vm.jobMetadata.locked && _vm.jobMetadata.status !== 'DONE' ? _c('v-btn', {
    staticClass: "px-1 ml-2",
    attrs: {
      "color": "warning",
      "x-small": ""
    },
    on: {
      "click": _vm.editParams
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" fas fa-pen ")])], 1) : _vm._e(), _vm.editMaxSize ? _c('v-btn', {
    staticClass: "px-1 mx-2",
    attrs: {
      "loading": _vm.loadingForm,
      "color": "success",
      "x-small": ""
    },
    on: {
      "click": _vm.validateEdit
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" fas fa-check ")])], 1) : _vm._e(), _vm.editMaxSize ? _c('v-btn', {
    staticClass: "px-1 mr-2",
    attrs: {
      "loading": _vm.loadingForm,
      "color": "error",
      "x-small": ""
    },
    on: {
      "click": _vm.cancelEditSize
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" fas fa-times ")])], 1) : _vm._e(), _vm.editMaxSize ? _c('v-btn', {
    staticClass: "px-1",
    attrs: {
      "loading": _vm.loadingForm,
      "color": "warning",
      "x-small": ""
    },
    on: {
      "click": _vm.resetSize
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" fas fa-redo ")])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "layout align-center justify-center"
  }, [_c('div', [_vm.jobMetadata.status === 'DONE' ? _c('span', {
    staticClass: "heightCube"
  }, [_vm._v(" " + _vm._s(_vm.jobMetadata.nestingResultSettings.maxSize.height) + " mm ")]) : _vm._e(), !_vm.editMaxSize && _vm.usesJobMaxSize ? _c('span', {
    staticClass: "heightCube blue--text font-weight-medium",
    on: {
      "click": _vm.editParams
    }
  }, [_vm._v(" " + _vm._s(_vm.jobMaxSize.height) + " mm ")]) : _vm._e(), !_vm.editMaxSize && _vm.usesLineMaxSize ? _c('span', {
    staticClass: "heightCube",
    on: {
      "click": _vm.editParams
    }
  }, [_vm._v(" " + _vm._s(_vm.lineMaxSize.height) + " mm ")]) : _vm._e(), !_vm.editMaxSize && _vm.usesPrinterMaxSize ? _c('span', {
    staticClass: "heightCube",
    on: {
      "click": _vm.editParams
    }
  }, [_vm._v(" " + _vm._s(_vm.printerMaxSize.height) + " mm ")]) : _vm._e(), _vm.jobMetadata.status === 'DONE' ? _c('span', {
    staticClass: "widthCube",
    on: {
      "click": _vm.editParams
    }
  }, [_vm._v(" " + _vm._s(_vm.jobMetadata.nestingResultSettings.maxSize.width) + " mm ")]) : _vm._e(), !_vm.editMaxSize && _vm.usesJobMaxSize ? _c('span', {
    staticClass: "widthCube blue--text font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.jobMaxSize.width) + " mm ")]) : _vm._e(), !_vm.editMaxSize && _vm.usesLineMaxSize ? _c('span', {
    staticClass: "widthCube",
    on: {
      "click": _vm.editParams
    }
  }, [_vm._v(" " + _vm._s(_vm.lineMaxSize.width) + " mm ")]) : _vm._e(), !_vm.editMaxSize && _vm.usesPrinterMaxSize ? _c('span', {
    staticClass: "widthCube",
    on: {
      "click": _vm.editParams
    }
  }, [_vm._v(" " + _vm._s(_vm.printerMaxSize.width) + " mm ")]) : _vm._e(), _vm.jobMetadata.status === 'DONE' ? _c('span', {
    staticClass: "depthCube"
  }, [_vm._v(" " + _vm._s(_vm.jobMetadata.nestingResultSettings.maxSize.depth) + " mm ")]) : _vm._e(), !_vm.editMaxSize && _vm.usesJobMaxSize ? _c('span', {
    staticClass: "depthCube blue--text font-weight-medium",
    on: {
      "click": _vm.editParams
    }
  }, [_vm._v(" " + _vm._s(_vm.jobMaxSize.depth) + " mm ")]) : _vm._e(), !_vm.editMaxSize && _vm.usesLineMaxSize ? _c('span', {
    staticClass: "depthCube",
    on: {
      "click": _vm.editParams
    }
  }, [_vm._v(" " + _vm._s(_vm.lineMaxSize.depth) + " mm ")]) : _vm._e(), !_vm.editMaxSize && _vm.usesPrinterMaxSize ? _c('span', {
    staticClass: "depthCube",
    on: {
      "click": _vm.editParams
    }
  }, [_vm._v(" " + _vm._s(_vm.printerMaxSize.depth) + " mm ")]) : _vm._e(), _vm.editMaxSize ? _c('v-form', [_c('span', {
    class: {
      'heightCubeTextField1': String(_vm.formMaxHeight).length <= 1,
      'heightCubeTextField2': String(_vm.formMaxHeight).length === 2,
      'heightCubeTextField3': String(_vm.formMaxHeight).length === 3,
      'heightCubeTextField4': String(_vm.formMaxHeight).length > 3
    }
  }, [_c('v-text-field', {
    class: {
      'textFieldSize1': String(_vm.formMaxHeight).length <= 1,
      'textFieldSize2': String(_vm.formMaxHeight).length === 2,
      'textFieldSize3': String(_vm.formMaxHeight).length === 3,
      'textFieldSize4': String(_vm.formMaxHeight).length > 3
    },
    attrs: {
      "type": "number",
      "rules": _vm.ruleSize,
      "suffix": "mm",
      "solo": "",
      "min": "0"
    },
    on: {
      "input": function input($event) {
        return _vm.checkValue(_vm.formMaxHeight);
      }
    },
    model: {
      value: _vm.formMaxHeight,
      callback: function callback($$v) {
        _vm.formMaxHeight = $$v;
      },
      expression: "formMaxHeight"
    }
  })], 1), _c('span', {
    class: {
      'widthCubeTextField1': String(_vm.formMaxWidth).length <= 1,
      'widthCubeTextField2': String(_vm.formMaxWidth).length === 2,
      'widthCubeTextField3': String(_vm.formMaxWidth).length === 3,
      'widthCubeTextField4': String(_vm.formMaxWidth).length > 3
    }
  }, [_c('v-text-field', {
    class: {
      'textFieldSize1': String(_vm.formMaxWidth).length <= 1,
      'textFieldSize2': String(_vm.formMaxWidth).length === 2,
      'textFieldSize3': String(_vm.formMaxWidth).length === 3,
      'textFieldSize4': String(_vm.formMaxWidth).length > 3
    },
    attrs: {
      "type": "number",
      "rules": _vm.ruleSize,
      "suffix": "mm",
      "solo": "",
      "min": "0"
    },
    model: {
      value: _vm.formMaxWidth,
      callback: function callback($$v) {
        _vm.formMaxWidth = $$v;
      },
      expression: "formMaxWidth"
    }
  })], 1), _c('span', {
    staticClass: "depthCubeTextField1"
  }, [_c('v-text-field', {
    class: {
      'textFieldSize1': String(_vm.formMaxDepth).length <= 1,
      'textFieldSize2': String(_vm.formMaxDepth).length === 2,
      'textFieldSize3': String(_vm.formMaxDepth).length === 3,
      'textFieldSize4': String(_vm.formMaxDepth).length > 3
    },
    attrs: {
      "type": "number",
      "rules": _vm.ruleSize,
      "suffix": "mm",
      "solo": "",
      "min": "0"
    },
    model: {
      value: _vm.formMaxDepth,
      callback: function callback($$v) {
        _vm.formMaxDepth = $$v;
      },
      expression: "formMaxDepth"
    }
  })], 1)]) : _vm._e(), _c('v-img', {
    attrs: {
      "src": require("../../../../public/img/icons/jobSize.png"),
      "alt": "cube sizes",
      "max-width": "150px"
    }
  })], 1)])]), _c('v-col', {
    staticClass: "px-0 pt-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "text-center pb-4 d-flex align-center justify-center"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("OnlyAssignedParts")))])]), _vm.formOnlyAssignedParts !== _vm.jobMetadata.onlyAssignedParts ? _c('v-btn', {
    attrs: {
      "loading": _vm.loadingForm,
      "color": "success",
      "x-small": ""
    },
    on: {
      "click": _vm.validateEdit
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" fas fa-check ")])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "layout align-center justify-center"
  }, [_c('div', {
    staticClass: "onlyAssignedParts"
  }, [_c('v-switch', {
    model: {
      value: _vm.formOnlyAssignedParts,
      callback: function callback($$v) {
        _vm.formOnlyAssignedParts = $$v;
      },
      expression: "formOnlyAssignedParts"
    }
  }), _c('span', {
    staticClass: "label-onlyAssignedParts"
  }, [_vm._v(" " + _vm._s(_vm.formOnlyAssignedParts ? _vm.$t('Activated') : _vm.$t('Deactivated')) + " ")])], 1), _vm.formOnlyAssignedParts ? _c('v-img', {
    attrs: {
      "src": require("../../../../public/img/icons/onlyAssignedParts.png"),
      "alt": "assigned parts",
      "max-height": "140px",
      "max-width": "140px"
    }
  }) : _c('v-img', {
    attrs: {
      "src": require("../../../../public/img/icons/allParts.png"),
      "alt": "all parts",
      "max-height": "140px",
      "max-width": "140px"
    }
  })], 1)])], 1) : _vm.upToDate && (_vm.jobMetadata.locked || _vm.jobMetadata.status !== 'PLANNING') ? _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "px-0 pt-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "text-center pb-2 d-flex align-center justify-center"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("Workspace")))])]), _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          attrs: {
            "small": ""
          }
        }, 'v-icon', attrs, false), on), [_vm._v(" fas fa-question-circle ")])];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("MaxSizeUsedText")))])])], 1), _c('div', {
    staticClass: "layout align-center justify-center"
  }, [_c('div', [_c('span', {
    staticClass: "heightCube"
  }, [_vm._v(" " + _vm._s(_vm.jobMetadata.nestingResultSettings.maxSize.height) + " mm ")]), _c('span', {
    staticClass: "widthCube"
  }, [_vm._v(" " + _vm._s(_vm.jobMetadata.nestingResultSettings.maxSize.width) + " mm ")]), _c('span', {
    staticClass: "depthCube"
  }, [_vm._v(" " + _vm._s(_vm.jobMetadata.nestingResultSettings.maxSize.depth) + " mm ")]), _c('v-img', {
    attrs: {
      "src": require("../../../../public/img/icons/jobSize.png"),
      "alt": "cube sizes",
      "max-width": "150px"
    }
  })], 1)])]), _c('v-col', {
    staticClass: "px-0 pt-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "text-center pb-4 d-flex align-center justify-center"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("OnlyAssignedParts")))])])]), _c('div', {
    staticClass: "layout align-center justify-center"
  }, [_c('div', {
    staticClass: "onlyAssignedParts"
  }, [_c('v-switch', {
    attrs: {
      "disabled": ""
    },
    model: {
      value: _vm.formOnlyAssignedParts,
      callback: function callback($$v) {
        _vm.formOnlyAssignedParts = $$v;
      },
      expression: "formOnlyAssignedParts"
    }
  }), _c('span', {
    staticClass: "label-onlyAssignedParts"
  }, [_vm._v(" " + _vm._s(_vm.formOnlyAssignedParts ? _vm.$t('Activated') : _vm.$t('Deactivated')) + " ")])], 1), _vm.formOnlyAssignedParts ? _c('v-img', {
    attrs: {
      "src": require("../../../../public/img/icons/onlyAssignedParts.png"),
      "alt": "assigned parts",
      "max-height": "140px",
      "max-width": "140px"
    }
  }) : _c('v-img', {
    attrs: {
      "src": require("../../../../public/img/icons/allParts.png"),
      "alt": "all parts",
      "max-height": "140px",
      "max-width": "140px"
    }
  })], 1)])], 1) : _vm._e(), _c('v-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.upToDate,
      expression: "!upToDate"
    }]
  }, [_c('v-col', {
    staticClass: "pa-6",
    attrs: {
      "cols": "12",
      "align": "center"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "color": "orange",
      "size": "150",
      "width": 8,
      "indeterminate": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": require("../../../../public/img/icons/smallLogo.svg"),
      "alt": "small logo",
      "contain": "",
      "aspect-ratio": "1",
      "width": "80"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": _vm.$vuetify.breakpoint.lgAndDown ? 12 : 6
    }
  }, [_c('v-expansion-panels', {
    model: {
      value: _vm.expandedParameter,
      callback: function callback($$v) {
        _vm.expandedParameter = $$v;
      },
      expression: "expandedParameter"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', {
    staticClass: "title-panel py-2"
  }, [_c('v-icon', {
    staticClass: "mr-4",
    attrs: {
      "color": "white"
    }
  }, [_vm._v(" fas fa-info-circle ")]), _c('span', {
    staticClass: "white--text"
  }, [_vm._v(_vm._s(_vm.$t("JobInformation")))])], 1), _c('v-expansion-panel-content', {
    staticClass: "pt-8 pb-8"
  }, [_vm.upToDate && !_vm.jobMetadata.locked ? _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "px-0 pt-0",
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "text-center pb-4"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("UsedWorkspace")))])]), _c('div', {
    staticClass: "layout align-center justify-center"
  }, [_c('div', [_c('span', {
    staticClass: "heightCube"
  }, [_vm._v(" " + _vm._s(_vm.jobMetadata.nestingSize.height.toFixed(0)) + " mm ")]), _c('span', {
    staticClass: "widthCube"
  }, [_vm._v(" " + _vm._s(_vm.jobMetadata.nestingSize.width.toFixed(0)) + " mm ")]), _c('span', {
    staticClass: "depthCube"
  }, [_vm._v(" " + _vm._s(_vm.jobMetadata.nestingSize.depth.toFixed(0)) + " mm ")]), _c('v-img', {
    staticClass: "imageCube",
    attrs: {
      "src": require("../../../../public/img/icons/jobSize.png"),
      "alt": "cube sizes",
      "max-width": "150px"
    }
  })], 1)])]), _vm.showWorkspaceUtilization ? _c('v-col', {
    staticClass: "px-0 pt-0",
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "text-center pb-4"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("WorkspaceUtilization")))])]), _c('div', {
    staticClass: "layout align-center"
  }, [_c('div', [_c('span', {
    staticClass: "freeSpaceCube"
  }, [_vm._v(" " + _vm._s(_vm.$t("Free")) + " : " + _vm._s(_vm.jobFreeHeightPercentage) + " % ")])]), _c('div', [_c('span', {
    staticClass: "usedSpaceCube"
  }, [_vm._v(" " + _vm._s(_vm.$t("Used")) + " : " + _vm._s(_vm.jobUsedHeightPercentage) + " %, " + _vm._s(_vm.$t("including")) + " : ")])]), _c('div', [_c('span', {
    staticClass: "powderSpaceCube"
  }, [_vm._v(" - " + _vm._s(_vm.$t("Powder")) + " : " + _vm._s(_vm.jobPowderPercentage) + " % ")])]), _c('div', [_c('span', {
    staticClass: "partsSpaceCube"
  }, [_vm._v(" - " + _vm._s(_vm.$t("Parts")) + " : " + _vm._s(_vm.jobPartPercentage) + " % ")])]), _c('v-img', {
    attrs: {
      "src": require("../../../../public/img/icons/usage.png"),
      "alt": "usage",
      "max-height": "140px",
      "max-width": "140px"
    }
  })], 1)]) : _c('v-col', {
    staticClass: "px-0 pt-0",
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "text-center pb-6"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("TotalMaterialVolume")))])]), _c('div', {
    staticClass: "layout align-center justify-center"
  }, [_c('div', [_c('span', {
    staticClass: "partTotalVolume"
  }, [_vm._v(" " + _vm._s(_vm.improveVolumeReadability(_vm.partsTotalVolume)) + " ")])]), _c('v-img', {
    attrs: {
      "src": require("../../../../public/img/icons/volume.png"),
      "alt": "volume",
      "max-height": "120px",
      "max-width": "120px"
    }
  })], 1)]), _c('v-col', {
    staticClass: "px-0 pt-0",
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "text-center pb-4"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("PartsMinimumSpacing")))])]), _c('div', {
    staticClass: "layout align-center justify-center"
  }, [_vm.jobMetadata.status !== 'DONE' ? _c('div', [_vm.usesLineMinimumSpacing ? _c('span', {
    staticClass: "spacingCube"
  }, [_vm._v(" " + _vm._s(_vm.lineMinimumSpacing) + " mm ")]) : _vm._e(), _vm.usesPrinterMinimumSpacing ? _c('span', {
    staticClass: "spacingCube"
  }, [_vm._v(" " + _vm._s(_vm.printerMinimumSpacing) + " mm ")]) : _vm._e()]) : _c('div', [_c('span', {
    staticClass: "spacingCube"
  }, [_vm._v(" " + _vm._s(_vm.jobMetadata.nestingResultSettings.minimumSpacing) + " mm ")])]), _c('v-img', {
    attrs: {
      "src": require("../../../../public/img/icons/spacingSmall.png"),
      "alt": "spacing small",
      "max-height": "140px",
      "max-width": "400px",
      "contain": ""
    }
  })], 1)])], 1) : _vm.upToDate && _vm.jobMetadata.locked ? _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "px-0 pt-0",
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "text-center pb-4"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("UsedWorkspace")))])]), _c('div', {
    staticClass: "layout align-center justify-center"
  }, [_c('div', [_c('span', {
    staticClass: "heightCube"
  }, [_vm._v(" " + _vm._s(_vm.jobMetadata.nestingSize.height.toFixed(0)) + " mm ")]), _c('span', {
    staticClass: "widthCube"
  }, [_vm._v(" " + _vm._s(_vm.jobMetadata.nestingSize.width.toFixed(0)) + " mm ")]), _c('span', {
    staticClass: "depthCube"
  }, [_vm._v(" " + _vm._s(_vm.jobMetadata.nestingSize.depth.toFixed(0)) + " mm ")]), _c('v-img', {
    staticClass: "imageCube",
    attrs: {
      "src": require("../../../../public/img/icons/jobSize.png"),
      "alt": "cube sizes",
      "max-width": "150px"
    }
  })], 1)])]), _vm.showWorkspaceUtilization ? _c('v-col', {
    staticClass: "px-0 pt-0",
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "text-center pb-4"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("WorkspaceUtilization")))])]), _c('div', {
    staticClass: "layout align-center"
  }, [_c('div', [_c('span', {
    staticClass: "freeSpaceCube"
  }, [_vm._v(" " + _vm._s(_vm.$t("Free")) + " : " + _vm._s(_vm.jobFreeHeightPercentage) + " % ")])]), _c('div', [_c('span', {
    staticClass: "usedSpaceCube"
  }, [_vm._v(" " + _vm._s(_vm.$t("Used")) + " : " + _vm._s(_vm.jobUsedHeightPercentage) + " %, " + _vm._s(_vm.$t("including")) + " : ")])]), _c('div', [_c('span', {
    staticClass: "powderSpaceCube"
  }, [_vm._v(" - " + _vm._s(_vm.$t("Powder")) + " : " + _vm._s(_vm.jobPowderPercentage) + " % ")])]), _c('div', [_c('span', {
    staticClass: "partsSpaceCube"
  }, [_vm._v(" - " + _vm._s(_vm.$t("Parts")) + " : " + _vm._s(_vm.jobPartPercentage) + " % ")])]), _c('v-img', {
    attrs: {
      "src": require("../../../../public/img/icons/usage.png"),
      "alt": "usage",
      "max-height": "140px",
      "max-width": "140px"
    }
  })], 1)]) : _c('v-col', {
    staticClass: "px-0 pt-0",
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "text-center pb-6"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("TotalMaterialVolume")))])]), _c('div', {
    staticClass: "layout align-center justify-space-around"
  }, [_c('div', [_c('span', {
    staticClass: "partTotalVolume"
  }, [_vm._v(" " + _vm._s(_vm.improveVolumeReadability(_vm.partsTotalVolume)) + " ")])]), _c('v-img', {
    attrs: {
      "src": require("../../../../public/img/icons/volume.png"),
      "alt": "volume",
      "max-height": "120px",
      "max-width": "120px"
    }
  })], 1)]), _c('v-col', {
    staticClass: "px-0 pt-0",
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "text-center pb-4"
  }, [_c('span', [_c('h3', [_vm._v(_vm._s(_vm.$t("PartsMinimumSpacing")))])])]), _c('div', {
    staticClass: "layout align-center justify-center"
  }, [_c('div', [_c('span', {
    staticClass: "spacingCube"
  }, [_vm._v(" " + _vm._s(_vm.jobMetadata.nestingResultSettings.minimumSpacing) + " mm ")])]), _c('v-img', {
    attrs: {
      "src": require("../../../../public/img/icons/spacingSmall.png"),
      "alt": "spacing small",
      "max-height": "140px",
      "max-width": "400px",
      "contain": ""
    }
  })], 1)])], 1) : _vm._e(), _c('v-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.upToDate,
      expression: "!upToDate"
    }]
  }, [_c('v-col', {
    staticClass: "pa-6",
    attrs: {
      "cols": "12",
      "align": "center"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "color": "orange",
      "size": "150",
      "width": 8,
      "indeterminate": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": require("../../../../public/img/icons/smallLogo.svg"),
      "alt": "small logo",
      "contain": "",
      "aspect-ratio": "1",
      "width": "80"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }