<template lang="html" src="./jobDashboardParameters.template.vue"></template>

<style lang="scss" src="./jobDashboardParameters.scss"></style>

<script>
import { ApiErrorParser, EventBus } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./jobDashboardParameters.i18n');

export default {
  name: 'JobDashboardParameters',
  props: {
    jobMetadata: {
      type: Object,
      required: true,
    },
    lineMetadata: {
      type: Object,
      required: true,
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      expandedParameter: 0,
      expandedInfo: 0,
      editMaxSize: false,
      formMaxHeight: null,
      formMaxWidth: null,
      formMaxDepth: null,
      formOnlyAssignedParts: false,
      loadingForm: false,
      ruleSize: null,
      jobMaxSize: null,
      lineMaxSize: null,
      printerMaxSize: null,
      lineMinimumSpacing: null,
      printerMinimumSpacing: null,
      lineZStacking: null,
      printerZStacking: null,
      usesJobMaxSize: false,
      usesLineMaxSize: false,
      usesPrinterMaxSize: false,
      usesLineMinimumSpacing: false,
      usesPrinterMinimumSpacing: false,
      usesLineZStacking: false,
      usesPrinterZStacking: false,
      jobFreeHeightPercentage: '?',
      jobUsedHeightPercentage: '?',
      jobPowderPercentage: '?',
      jobPartPercentage: '?',
      requireUpdate: false,
      upToDate: true,
      showWorkspaceUtilization: true,
      partsTotalVolume: 0,
    };
  },
  created() {
    EventBus.$on('updateJobParameters', () => {
      this.requireUpdate = true;
      setTimeout(this.updateJobParameters,250);
    });
    this.ruleSize = [(value) => String(value).length <= 4 || '< 10000'];
    this.initData();
    this.initForm();
  },
  updated() {
    this.updateJobParameters();
  },
  beforeDestroy() {
    EventBus.$off('updateJobParameters', this.updateJobParameters);
  },
  methods: {
    updateJobParameters() {
      if (this.requireUpdate) {
        this.requireUpdate = false;
        this.initData();
        this.initForm();
        this.upToDate = true;
      }
    },
    initData() {
      this.jobMaxSize = this.jobMetadata.maxSize;
      this.lineMaxSize = this.lineMetadata.lineData.maxSize;
      this.lineMinimumSpacing = this.lineMetadata.lineData.minimumSpacing;
      this.lineZStacking = this.lineMetadata.lineData.zStacking;
      this.printerMaxSize = this.lineMetadata.printer.build_volume;
      this.printerMinimumSpacing = this.lineMetadata.printer.minimumSpacing;
      this.printerZStacking = this.lineMetadata.printer.zStacking;

      if (this.jobMetadata.status !== 'DONE') {
        if (this.jobMaxSize) {
          this.usesJobMaxSize = true;
          this.usesLineMaxSize = false;
          this.usesPrinterMaxSize = false;
        } else if (this.lineMaxSize) {
          this.usesJobMaxSize = false;
          this.usesLineMaxSize = true;
          this.usesPrinterMaxSize = false;
        } else {
          this.usesJobMaxSize = false;
          this.usesLineMaxSize = false;
          this.usesPrinterMaxSize = true;
        }
      }

      if (this.lineMinimumSpacing) {
        this.usesLineMinimumSpacing = true;
        this.usesPrinterMinimumSpacing = false;
      } else {
        this.usesLineMinimumSpacing = false;
        this.usesPrinterMinimumSpacing = true;
      }

      if (this.lineZStacking) {
        this.usesLineZStacking = true;
        this.usesPrinterZStacking = false;
        this.showWorkspaceUtilization = this.lineZStacking;
      } else {
        this.usesLineZStacking = false;
        this.usesPrinterZStacking = true;
        this.showWorkspaceUtilization = this.printerZStacking;
      }

      const heightMax = this.usesJobMaxSize
        ? this.jobMaxSize.height
        : this.usesLineMaxSize
          ? this.lineMaxSize.height
          : this.printerMaxSize.height;

      const widthMax = this.usesJobMaxSize
        ? this.jobMaxSize.width
        : this.usesLineMaxSize
          ? this.lineMaxSize.width
          : this.printerMaxSize.width;

      const depthMax = this.usesJobMaxSize
        ? this.jobMaxSize.depth
        : this.usesLineMaxSize
          ? this.lineMaxSize.depth
          : this.printerMaxSize.depth;

      this.partsTotalVolume = this.getCurrentJobPartsVolume();

      if (
        this.jobMetadata.nestingSize.height <= heightMax &&
        this.jobMetadata.nestingSize.width <= widthMax &&
        this.jobMetadata.nestingSize.depth <= depthMax
      ) {
        const zNesting = this.jobMetadata.nestingSize.height;

        this.jobUsedHeightPercentage = ((100 * zNesting) / heightMax).toFixed(0);
        this.jobFreeHeightPercentage = 100 - this.jobUsedHeightPercentage;

        const totalVolume = heightMax * widthMax * depthMax;
        this.jobPartPercentage = (
          (100 * this.partsTotalVolume) /
          totalVolume
        ).toFixed(0);
        this.jobPowderPercentage =
          this.jobUsedHeightPercentage - this.jobPartPercentage;
      }
    },
    initForm() {
      this.formMaxHeight = this.usesJobMaxSize
        ? this.jobMaxSize.height
        : this.usesLineMaxSize
          ? this.lineMaxSize.height
          : this.printerMaxSize.height;
      this.formMaxWidth = this.usesJobMaxSize
        ? this.jobMaxSize.width
        : this.usesLineMaxSize
          ? this.lineMaxSize.width
          : this.printerMaxSize.width;
      this.formMaxDepth = this.usesJobMaxSize
        ? this.jobMaxSize.depth
        : this.usesLineMaxSize
          ? this.lineMaxSize.depth
          : this.printerMaxSize.depth;

      this.formOnlyAssignedParts =
        this.jobMetadata.status === 'PLANNING' && !this.jobMetadata.locked
          ? this.jobMetadata.onlyAssignedParts
          : this.jobMetadata.nestingResultSettings.onlyAssignedParts;
    },
    getCurrentJobPartsVolume() {
      let volume = 0;
      for (const productionPart of this.jobMetadata.parts) {
        volume += productionPart.volume * productionPart.quantity;
      }
      return volume;
    },
    editParams() {
      if (!this.jobMetadata.locked) {
        this.initForm();
        this.editMaxSize = true;
      }
    },
    validateEdit() {
      const printerBuildVolume = this.$BcmModel.PrinterBuildVolume.constructFromObject({width: parseInt(this.formMaxWidth), depth: parseInt(this.formMaxDepth), height: parseInt(this.formMaxHeight)});
      const modifySupplierPrinterLineJobSettingsBody = this.$BcmModel.ModifySupplierPrinterLineJobSettingsBody.constructFromObject({maxSize: printerBuildVolume, onlyAssignedParts: this.formOnlyAssignedParts});
      this.loadingForm = true;

      this.$apiInstance
        .modifySupplierPrinterLineJobSettings(
          this.$route.params.supplierUUID,
          this.$route.params.printerBrand,
          this.$route.params.printerModel,
          this.lineMetadata.material,
          this.jobMetadata.number,
          modifySupplierPrinterLineJobSettingsBody
        )
        .then(
          (response) => {
            this.upToDate = false;
            this.loadingForm = false;
            this.editMaxSize = false;
            EventBus.$emit(
              'reloadJob',
              this.lineMetadata.material,
              this.jobMetadata.number
            );
          },
          (error) => {
            this.loadingForm = false;
            this.editMaxSize = false;
            this.$notification.notify('ERROR', ApiErrorParser.parse(error));
          }
        );
    },
    cancelEditSize() {
      this.editMaxSize = false;
    },
    resetSize() {
      const modifySupplierPrinterLineJobSettingsBody = this.$BcmModel.ModifySupplierPrinterLineJobSettingsBody.constructFromObject({onlyAssignedParts: this.formOnlyAssignedParts});

      this.loadingForm = true;

      this.$apiInstance
        .modifySupplierPrinterLineJobSettings(
          this.$route.params.supplierUUID,
          this.$route.params.printerBrand,
          this.$route.params.printerModel,
          this.lineMetadata.material,
          this.jobMetadata.number,
          modifySupplierPrinterLineJobSettingsBody
        )
        .then(
          (response) => {
            EventBus.$emit(
              'reloadJob',
              this.lineMetadata.material,
              this.jobMetadata.number
            );
            this.upToDate = false;
            this.loadingForm = false;
            this.editMaxSize = false;
          },
          (error) => {
            this.loadingForm = false;
            this.editMaxSize = false;
            ApiErrorParser.parse(error);
          }
        );
    },
    checkValue(formElement) {
      if (formElement > 9999) {
        this.formMaxHeight = 9999;
      }
    },
    improveVolumeReadability(volumeInMillimeter) {
      let improvedValue = 0;
      switch (true) {
      case volumeInMillimeter < 1e3:
        improvedValue = `${Math.ceil(volumeInMillimeter)} mm³`;
        break;
      case volumeInMillimeter >= 1e3 && volumeInMillimeter < 1e6:
        improvedValue = `${
          Math.ceil((volumeInMillimeter / 1e3) * 1e2) / 1e2
        } cm³`;
        break;
      case volumeInMillimeter >= 1e6 && volumeInMillimeter < 1e9:
        improvedValue = `${
          Math.ceil((volumeInMillimeter / 1e6) * 1e2) / 1e2
        } dm³`;
        break;
      case volumeInMillimeter >= 1e9:
        improvedValue = `${
          Math.ceil((volumeInMillimeter / 1e9) * 1e2) / 1e2
        } m³`;
        break;
      default:
        improvedValue = 'value';
      }
      return improvedValue;
    },
  },
};
</script>
