var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-printerProduction"
  }, [_c('v-dialog', {
    staticClass: "no-rounded-corner",
    attrs: {
      "persistent": "",
      "fullscreen": "",
      "no-click-animation": "",
      "content-class": "page-printerProduction main-dialog page-printerProduction"
    },
    model: {
      value: _vm.fullscreen,
      callback: function callback($$v) {
        _vm.fullscreen = $$v;
      },
      expression: "fullscreen"
    }
  }, [_c('v-card', {
    staticClass: "main-card scrollBar no-rounded-corner"
  }, [_c('v-toolbar', {
    staticClass: "py-0 main-toolbar menuBackground"
  }, [_c('v-toolbar-items', [_c('v-img', {
    staticClass: "mr-4 ml-2",
    attrs: {
      "src": require("../../assets/logo.png"),
      "contain": "",
      "aspect-ratio": "1",
      "width": "110"
    }
  })], 1), _c('v-divider', {
    attrs: {
      "vertical": "",
      "dark": ""
    }
  }), _c('v-toolbar-items', [_c('v-tabs', {
    attrs: {
      "dark": "",
      "slider-size": "4",
      "height": "100%",
      "show-arrows": "",
      "background-color": "transparent"
    },
    model: {
      value: _vm.tabIndex,
      callback: function callback($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, [_c('v-tab', {
    staticClass: "menuBackground text-none",
    attrs: {
      "color": "transparent"
    }
  }, [_c('v-img', {
    staticClass: "mr-2",
    attrs: {
      "src": require("../../../public/img/icons/PlanningWhite.png"),
      "contain": "",
      "aspect-ratio": "1",
      "width": "32",
      "color": "white"
    }
  }), _c('span', [_vm._v("1 - " + _vm._s(_vm.$t("Planning")))])], 1), _c('v-tab', {
    staticClass: "menuBackground text-none"
  }, [_c('v-img', {
    staticClass: "mr-2",
    attrs: {
      "src": require("../../../public/img/icons/icon3DprinterWhite.png"),
      "contain": "",
      "aspect-ratio": "1",
      "width": "32"
    }
  }), _c('span', [_vm._v("2 - " + _vm._s(_vm.$t("Production")))])], 1), _c('v-tab', {
    staticClass: "menuBackground text-none"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "white",
      "size": "32px"
    }
  }, [_vm._v(" far fa-file-archive ")]), _c('span', [_vm._v("3 - " + _vm._s(_vm.$t("Archives")))])], 1)], 1)], 1), _c('v-spacer'), _c('v-divider', {
    attrs: {
      "vertical": "",
      "dark": ""
    }
  }), _c('v-icon', {
    staticClass: "ml-6",
    attrs: {
      "color": "white",
      "small": ""
    }
  }, [_vm._v(" fas fa-print ")]), _c('span', {
    staticClass: "ml-4 mr-4 white--text"
  }, [_vm._v(" " + _vm._s(_vm.printerBrand.replaceAll('_', ' ')) + " - " + _vm._s(_vm.printerModel.replaceAll('_', ' ')) + " ")]), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          staticClass: "text-none mr-6",
          attrs: {
            "small": "",
            "color": "white",
            "outlined": "",
            "to": {
              name: 'Administration',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID)
              }
            }
          },
          on: {
            "click": _vm.openAdministrationPrintersTab
          }
        }, on), [_c('v-icon', {
          staticClass: "mt-n1",
          attrs: {
            "small": "",
            "color": "white"
          }
        }, [_vm._v(" fas fa-cog ")]), _c('span', {
          staticClass: "text-none white--text"
        })], 1)];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("GoToPrinters")))])]), _c('v-divider', {
    attrs: {
      "vertical": "",
      "dark": ""
    }
  }), _vm.printer ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('span', _vm._g({
          staticClass: "mx-6 white--text font-weight-medium"
        }, on), [_vm._v(" " + _vm._s(_vm.printer.available) + " / " + _vm._s(_vm.printer.quantity) + " ")])];
      }
    }], null, false, 1563999049)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("PrintersAvailability")))])]) : _vm._e(), _c('v-divider', {
    attrs: {
      "vertical": "",
      "dark": ""
    }
  }), _c('v-btn', {
    staticClass: "mr-2 ml-4",
    attrs: {
      "icon": "",
      "to": {
        name: 'Production',
        params: {
          supplierUUID: "".concat(_vm.$route.params.supplierUUID)
        }
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "error"
    }
  }, [_vm._v(" fas fa-times ")])], 1)], 1), _vm.printer && _vm.materials ? _c('div', [_vm.tabIndex === 0 ? _c('div', [_c('PlanningDashboard', {
    attrs: {
      "printer": _vm.printer,
      "materials": _vm.materials
    }
  })], 1) : _vm.tabIndex === 1 ? _c('div', [_c('ProductionDashboard', {
    attrs: {
      "printer": _vm.printer
    }
  })], 1) : _vm.tabIndex === 2 ? _c('div', [_c('ArchivesDashboard', {
    attrs: {
      "printer": _vm.printer
    }
  })], 1) : _vm._e()]) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }