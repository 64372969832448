<template lang="html" src="./jobDashboardToolbar.template.vue"></template>

<style lang="scss" src="./jobDashboardToolbar.scss"></style>

<script>
import {
  ApiErrorParser,
  EventBus,
  DownloadSupplierPartFile,
  DownloadSupplierInternalPartFile,
  JobDownloader,
} from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./jobDashboardToolbar.i18n');

export default {
  name: 'JobDashboardToolbar',
  components: {
    JobDownloader,
  },
  props: {
    jobMetadata: {
      type: Object,
      required: true,
    },
    lineMetadata: {
      type: Object,
      required: true,
    },
    jobPlacements: {
      type: Array,
      required: true,
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      lockJobDialog: false,
      jobPartsDownloaded: false,
      jobPartsDownloading: false,
      jobPartsFiles: {},
      jobPartsExtensions: {},
      modifyJobStatusDialog: false,
      statusModification: null,
    };
  },
  created() {},
  mounted() {},
  methods: {
    async downloadJobPartsFile() {
      this.jobPartsDownloading = true;
      let partsToDownload = 0;
      let partsDownloaded = 0;
      const self = this;
      const references = {};
      for (const part of this.jobMetadata.parts) {
        if (!(part.part in references)) {
          references[part.part] = part.reference;
        }
      }
      for (const partPlacement of this.jobPlacements) {
        const partId = partPlacement.part;
        if (!(partId in this.jobPartsFiles)) {
          partsToDownload += 1;
          this.jobPartsFiles[partId] = 'downloading';
          if (references[partId] === 'ORDER') {
            DownloadSupplierPartFile.downloadSupplierPartFile(
              this.$apiInstance,
              this.$route.params.supplierUUID,
              partId
            )[0].then((response) => {
              self.jobPartsFiles[partId] = response.buffer;
              self.jobPartsExtensions[partId] = response.extension;
              partsDownloaded += 1;
            });
          } else {
            DownloadSupplierInternalPartFile.downloadSupplierInternalPartFile(
              this.$apiInstance,
              this.$route.params.supplierUUID,
              partId
            )[0].then((response) => {
              self.jobPartsFiles[partId] = response.buffer;
              self.jobPartsExtensions[partId] = response.extension;
              partsDownloaded += 1;
            });
          }
        }
      }
      let waitingTime = 100; //ms
      while (partsDownloaded !== partsToDownload) {
        await new Promise((resolve) => {
          setTimeout(resolve, waitingTime);
        });
        waitingTime = Math.min(waitingTime + 100, 1000);
      }
      this.jobPartsDownloading = false;
      this.jobPartsDownloaded = true;
    },
    closeJobDashboard() {
      EventBus.$emit('closeJobDashboard');
    },
    openJobDialog() {
      this.lockJobDialog = true;
    },
    closeJobDialog() {
      this.lockJobDialog = false;
    },
    confirmJobLockAction() {
      const modifySupplierPrinterLineJobLockBody = new this.$BcmModel.ModifySupplierPrinterLineJobLockBody(!this.jobMetadata.locked);
      this.$apiInstance
        .modifySupplierPrinterLineJobLock(
          this.$route.params.supplierUUID,
          this.$route.params.printerBrand,
          this.$route.params.printerModel,
          this.lineMetadata.material,
          this.jobMetadata.number,
          modifySupplierPrinterLineJobLockBody
        )
        .then(
          () => {
            EventBus.$emit(
              'reloadJob',
              this.lineMetadata.material,
              this.jobMetadata.number
            );
          },
          (error) => {
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.closeJobDialog();
        });
    },
    openJobStatusDialog(status) {
      this.statusModification = status;
      this.modifyJobStatusDialog = true;
    },
    closeJobStatusDialog() {
      this.statusModification = null;
      this.modifyJobStatusDialog = false;
    },
    sendJobToPlanning() {
      const modifySupplierPrinterLineJobStatusBody = new this.$BcmModel.ModifySupplierPrinterLineJobStatusBody(
        'PLANNING'
      );
      this.$apiInstance
        .modifySupplierPrinterLineJobStatus(
          this.$route.params.supplierUUID,
          this.$route.params.printerBrand,
          this.$route.params.printerModel,
          this.lineMetadata.material,
          this.jobMetadata.number,
          modifySupplierPrinterLineJobStatusBody
        )
        .then(
          () => {
            this.$notification.notify('SUCCESS',this.$t('JobSentToPlanning'));
            this.closeJobDashboard();
            EventBus.$emit(
              'changeTabAndOpenJob',
              'PLANNING',
              this.lineMetadata.material,
              this.jobMetadata.number
            );
          },
          (error) => {
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    sendJobToProduction() {
      const modifySupplierPrinterLineJobStatusBody = new this.$BcmModel.ModifySupplierPrinterLineJobStatusBody(
        'PRODUCTION'
      );
      const modifySupplierPrinterLineJobLockBody = new this.$BcmModel.ModifySupplierPrinterLineJobLockBody(true);
      if (!this.jobMetadata.locked) {
        this.$apiInstance
          .modifySupplierPrinterLineJobLock(
            this.$route.params.supplierUUID,
            this.$route.params.printerBrand,
            this.$route.params.printerModel,
            this.lineMetadata.material,
            this.jobMetadata.number,
            modifySupplierPrinterLineJobLockBody
          )
          .then(
            () => {
              this.$apiInstance
                .modifySupplierPrinterLineJobStatus(
                  this.$route.params.supplierUUID,
                  this.$route.params.printerBrand,
                  this.$route.params.printerModel,
                  this.lineMetadata.material,
                  this.jobMetadata.number,
                  modifySupplierPrinterLineJobStatusBody
                )
                .then(
                  () => {
                    this.$notification.notify('SUCCESS',this.$t('JobSentToProduction'));
                    this.closeJobDashboard();
                    EventBus.$emit(
                      'changeTabAndOpenJob',
                      'PRODUCTION',
                      this.lineMetadata.material,
                      this.jobMetadata.number
                    );
                  },
                  (error) => {
                    this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
                  }
                );
            },
            (error) => {
              this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
            }
          );
      } else {
        this.$apiInstance
          .modifySupplierPrinterLineJobStatus(
            this.$route.params.supplierUUID,
            this.$route.params.printerBrand,
            this.$route.params.printerModel,
            this.lineMetadata.material,
            this.jobMetadata.number,
            modifySupplierPrinterLineJobStatusBody
          )
          .then(
            () => {
              this.$notification.notify('SUCCESS',this.$t('JobSentToProduction'));
              this.closeJobDashboard();
              EventBus.$emit(
                'changeTabAndOpenJob',
                'PRODUCTION',
                this.lineMetadata.material,
                this.jobMetadata.number
              );
            },
            (error) => {
              this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
            }
          );
      }
    },
    sendJobToArchives() {
      const modifySupplierPrinterLineJobStatusBody = new this.$BcmModel.ModifySupplierPrinterLineJobStatusBody(
        'DONE'
      );
      this.$apiInstance
        .modifySupplierPrinterLineJobStatus(
          this.$route.params.supplierUUID,
          this.$route.params.printerBrand,
          this.$route.params.printerModel,
          this.lineMetadata.material,
          this.jobMetadata.number,
          modifySupplierPrinterLineJobStatusBody
        )
        .then(
          () => {
            this.$notification.notify('SUCCESS',this.$t('JobSentToArchives'));
            this.closeJobDashboard();
            EventBus.$emit(
              'changeTabAndOpenJob',
              'ARCHIVES',
              this.lineMetadata.material,
              this.jobMetadata.number
            );
          },
          (error) => {
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
  },
};
</script>
