<template lang="html" src="./jobDashboard.template.vue"></template>

<style lang="scss" src="./jobDashboard.scss"></style>

<script>
import {
  JobViewer,
  DownloadSupplierPartViewer3dFile,
  DownloadSupplierInternalPartViewer3dFile,
} from '@cloudmanufacturingtechnologies/portal-components';
import JobDashboardToolbar from './jobDashboardToolbar/JobDashboardToolbar';
import JobDashboardParameters from './jobDashboardParameters/JobDashboardParameters';

const i18nData = require('./jobDashboard.i18n');

export default {
  name: 'JobDashboard',
  components: {
    JobDashboardToolbar,
    JobDashboardParameters,
    JobViewer,
  },
  props: {
    lineMetadata: {
      type: Object,
      required: true,
    },
    jobMetadata: {
      type: Object,
      required: true,
    },
    ordersMetadata: {
      type: Object,
      required: true,
    },
    jobArchived: {
      type: Boolean,
      default: false,
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      fullscreen: true,
      supplierUUID: this.$route.params.supplierUUID,
      printerBrand: this.$route.params.printerBrand,
      printerModel: this.$route.params.printerModel,
      supplierParts: {},
      jobPlacements: [],
      printerSize: {},
      partsDownloaded: false,
    };
  },
  created() {
    if (this.jobArchived) {
      this.getSupplierArchivedJob();
    } else {
      this.getSupplierJob();
    }
  },
  updated() {},
  methods: {
    getSupplierArchivedJob() {
      this.$apiInstance
        .getSupplierArchivedJob(
          this.supplierUUID,
          this.printerBrand,
          this.printerModel,
          this.lineMetadata.material,
          this.jobMetadata.number
        )
        .then(async(job) => {
          job.status = 'DONE';
          job.material = this.lineMetadata.material;

          this.jobPlacements = job.placements;
          this.printerSize = job.nestingResultSettings.maxSize;

          const self = this;
          let partsToDownload = 0;
          let partsDownloaded = 0;
          const references = {};
          for (const productionPart of job.parts) {
            if (!(productionPart.part in references)) {
              references[productionPart.part] = productionPart.reference;
            }
          }
          for (const partPlacement of this.jobPlacements) {
            const partId = partPlacement.part;
            if (!(partId in this.supplierParts)) {
              partsToDownload += 1;
              this.supplierParts[partId] = 'downloading'; // To avoid downloading multiple times same part and because download is asynchronous
              if (references[partId] === 'ORDER') {
                const req = DownloadSupplierPartViewer3dFile.downloadSupplierPartViewer3dFile(
                  this.$apiInstance,
                  this.supplierUUID,
                  partId,
                  Date.now().toString()
                );
                req.onloadend = function() {
                  self.supplierParts[partId] = req.response;
                  partsDownloaded += 1;
                };
              } else {
                const req = DownloadSupplierInternalPartViewer3dFile.downloadSupplierInternalPartViewer3dFile(
                  this.$apiInstance,
                  this.supplierUUID,
                  partId,
                  Date.now().toString()
                );
                req.onloadend = function() {
                  self.supplierParts[partId] = req.response;
                  partsDownloaded += 1;
                };
              }
            }
          }
          let waitingTime = 100; //ms
          while (partsDownloaded !== partsToDownload) {
            await new Promise((resolve) => {
              setTimeout(resolve, waitingTime);
            });
            waitingTime = Math.min(waitingTime + 100, 1000);
          }
          this.partsDownloaded = true;
        });
    },
    getSupplierJob() {
      this.$apiInstance
        .getSupplierPrinterLineJob(
          this.supplierUUID,
          this.printerBrand,
          this.printerModel,
          this.lineMetadata.material,
          this.jobMetadata.number
        )
        .then(async(job) => {
          job.material = this.lineMetadata.material;
          const jobPlacements = job.placements;
          this.jobPlacements = jobPlacements;
          if (this.jobMetadata.locked) {
            this.printerSize = this.jobMetadata.nestingResultSettings.maxSize;
          } else if (job.maxSize) {
            this.printerSize = job.maxSize;
          } else if (this.lineMetadata.lineData.maxSize) {
            this.printerSize = this.lineMetadata.lineData.maxSize;
          } else {
            this.printerSize = this.lineMetadata.printer.build_volume;
          }
          const self = this;
          let partsToDownload = 0;
          let partsDownloaded = 0;
          const references = {};
          for (const productionPart of job.parts) {
            if (!(productionPart.part in references)) {
              references[productionPart.part] = productionPart.reference;
            }
          }
          for (const partPlacement of jobPlacements) {
            const partId = partPlacement.part;
            if (!(partId in this.supplierParts)) {
              partsToDownload += 1;
              this.supplierParts[partId] = 'downloading'; // To avoid downloading multiple times same part and because download is asynchronous
              if (references[partId] === 'ORDER') {
                const req = DownloadSupplierPartViewer3dFile.downloadSupplierPartViewer3dFile(
                  this.$apiInstance,
                  this.supplierUUID,
                  partId,
                  Date.now().toString()
                );
                req.onloadend = function() {
                  self.supplierParts[partId] = req.response;
                  partsDownloaded += 1;
                };
              } else {
                const req = DownloadSupplierInternalPartViewer3dFile.downloadSupplierInternalPartViewer3dFile(
                  this.$apiInstance,
                  this.supplierUUID,
                  partId,
                  Date.now().toString()
                );
                req.onloadend = function() {
                  self.supplierParts[partId] = req.response;
                  partsDownloaded += 1;
                };
              }
            }
          }
          let waitingTime = 100; //ms
          while (partsDownloaded !== partsToDownload) {
            await new Promise((resolve) => {
              setTimeout(resolve, waitingTime);
            });
            waitingTime = Math.min(waitingTime + 100, 1000);
          }
          this.partsDownloaded = true;
        });
    },
  },
};
</script>
