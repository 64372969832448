<template lang="html" src="./pagePrinterProduction.template.vue"></template>

<style lang="scss" src="./pagePrinterProduction.scss"></style>

<script>
import { EventBus } from '@cloudmanufacturingtechnologies/portal-components';
import PlanningDashboard from './planningDashboard/PlanningDashboard';
import ProductionDashboard from './productionDashboard/ProductionDashboard';
import ArchivesDashboard from './archivesDashboard/ArchivesDashboard';

const i18nData = require('./pagePrinterProduction.i18n');

export default {
  name: 'PagePrinterProduction',
  components: {
    PlanningDashboard,
    ProductionDashboard,
    ArchivesDashboard,
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      printer: null,
      materials: null,
      supplierUUID: this.$route.params.supplierUUID,
      printerBrand: this.$route.params.printerBrand,
      printerModel: this.$route.params.printerModel,
      fullscreen: true,
      tabIndex: 0,
    };
  },
  created() {
    EventBus.$on('reloadMaterial', this.reloadMaterial);
    EventBus.$on('changeTabAndOpenJob', this.changeTabAndOpenJob);
    this.getSupplierPrinter();
    this.getMaterials();
  },
  mounted() {},
  beforeDestroy() {
    EventBus.$off('reloadMaterial', this.reloadMaterial);
    EventBus.$off('changeTabAndOpenJob', this.changeTabAndOpenJob);
  },
  methods: {
    handleQuery() {
      if (
        this.$route.query.tab &&
        this.$route.query.material &&
        this.$route.query.jobNumber
      ) {
        this.changeTabAndOpenJob(
          this.$route.query.tab,
          this.$route.query.material,
          parseInt(this.$route.query.jobNumber)
        );
        //this.$router.push(this.$route.path); // Should we remove query ?
      }
    },
    openAdministrationPrintersTab() {
      setTimeout(() => {
        EventBus.$emit('openAdministrationPrintersTab');
      }, 125);
    },
    getSupplierPrinter() {
      this.$apiInstance
        .getSupplierPrinter(
          this.supplierUUID,
          this.printerBrand,
          this.printerModel
        )
        .then((data) => {
          data.available = data.quantity;
          this.printer = data;
          this.computePrinterAvailability();
        });
    },
    getMaterials() {
      if (this.materials) {
        this.materials = [];
      }
      this.$apiInstance
        .getSupplierPrinterLinesMaterials(
          this.supplierUUID,
          this.printerBrand,
          this.printerModel
        )
        .then((data) => {
          this.materials = data;
          this.handleQuery();
        });
    },
    computePrinterAvailability() {
      this.$apiInstance
        .getSupplierPrinterLinesProductionMetadata(
          this.supplierUUID,
          this.printerBrand,
          this.printerModel
        )
        .then((result) => {
          this.printer.available = this.printer.quantity - result.jobs.length;
        });
    },
    changeTabAndOpenJob(tab, material, jobNumber) {
      this.computePrinterAvailability();
      switch (tab) {
      case 'PLANNING':
        this.tabIndex = 0;
        setTimeout(() => {
          EventBus.$emit('reloadJob', material, jobNumber, true);
        }, 100);
        break;
      case 'PRODUCTION':
        this.tabIndex = 1;
        setTimeout(() => {
          EventBus.$emit('reloadJob', material, jobNumber);
        }, 100);
        break;
      case 'ARCHIVES':
        this.tabIndex = 2;
        setTimeout(() => {
          EventBus.$emit('reloadJob', material, jobNumber);
        }, 100);
        break;
      }
    },
  },
};
</script>
