<template lang="html" src="./planningLineDashboard.template.vue"></template>

<style lang="scss" src="./planningLineDashboard.scss"></style>

<script>
import {
  ApiErrorParser,
  EventBus,
  PartAssignmentManager,
  DownloadSupplierPartViewer3dFile,
  DownloadSupplierInternalPartViewer3dFile,
} from '@cloudmanufacturingtechnologies/portal-components';
import JobDashboard from './../../../jobDashboard/JobDashboard';

const i18nData = require('./planningLineDashboard.i18n');

export default {
  name: 'PlanningLineDashboard',
  components: {
    JobDashboard,
    PartAssignmentManager,
  },
  props: {
    materialName: {
      type: String,
      required: true,
    },
    printer: {
      type: Object,
      required: true,
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      printerBrand: this.$route.params.printerBrand,
      printerModel: this.$route.params.printerModel,
      fullscreen: true,
      renderKey: 0,
      headersParts: [],
      jobs: [],
      parts: [],
      showPartsList: true,
      expanded: false,
      lineData: null,
      lineDataTooLong: false,
      jobSelected: null,
      nestingUpTime: '0:00',
      showLineSettings: false,
      usesLineMaxSize: false,
      usesPrinterMaxSize: false,
      usesLineMinimumSpacing: false,
      usesPrinterMinimumSpacing: false,
      usesLineZStacking: false,
      usesPrinterZStacking: false,
      editingMaxSize: false,
      editingMinimumSpacing: false,
      editingZSacking: false,
      loadingForm: false,
      formMaxHeight: null,
      formMaxWidth: null,
      formMaxDepth: null,
      formMinimumSpacing: 0,
      formZStacking: true,
      currentZStacking: true,
      ruleSize: [],
      modalPartSettings: false,
      viewer3dFiles: {},
      currentPartFileLoaded: false,
      currentPart: {},
      partUUIDToReload: null,
      lineReloaded: false,
    };
  },
  mounted() {
    EventBus.$on('closeModalPartSettings', this.closeModalPartSettings);
    EventBus.$on(
      'reloadPartAssignmentManager',
      this.reloadPartAssignmentManager
    );
    EventBus.$on('closeJobDashboard', () => {
      this.jobSelected = null;
    });
    EventBus.$on('reloadJob', this.reloadJob);
    this.headersParts = [
      { text: this.$t('PartName'), value: 'partName' },
      {
        text: this.$t('Deadline'),
        value: 'deadline',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('Quantities'),
        value: 'quantities',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('Actions'),
        value: 'actions',
        sortable: false,
        align: 'center',
      },
    ];
    this.ruleSize = [(value) => String(value).length <= 4 || 'Trop grand'];
    this.getPrinterLineMetadata();
    this.updateTimer();
  },
  updated() {
    if (this.$refs
      && this.$refs['displayOfJobs' + this.materialName] 
      && this.lineDataTooLong !== this.isOverflown(this.$refs['displayOfJobs' + this.materialName])) {
      this.lineDataTooLong = this.isOverflown(this.$refs['displayOfJobs' + this.materialName]);
    }
  },
  beforeDestroy() {
    EventBus.$off('closeModalPartSettings', this.closeModalPartSettings);
    EventBus.$off(
      'reloadPartAssignmentManager',
      this.reloadPartAssignmentManager
    );
    EventBus.$off('reloadJob', this.reloadJob);
  },
  methods: {
    isOverflown(element) {
      return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
    },
    reloadPartAssignmentManager(part, materialName, jobNumber) {
      if (materialName === this.materialName) {
        if (jobNumber > 0) {
          this.reloadJob(materialName, jobNumber);
        } else {
          this.partUUIDToReload = part.part;
          this.getPrinterLineMetadata();
        }
      }
    },
    clickJob(job) {
      const line = {
        parts: this.parts,
        jobs: this.jobs,
        lineData: this.lineData,
        material: this.materialName,
        printer: this.printer,
      };
      this.jobSelected = job;
      this.lineSelected = line;
      EventBus.$emit('updateJobParameters');

      setTimeout(() => {
        EventBus.$emit('printerLineDataReloaded');
      }, 0);
    },
    async reloadJob(material, jobNumber, reloadLineMetadata = true) {
      if (material === this.materialName) {
        this.lineReloaded = false;
        if (reloadLineMetadata) {
          this.getPrinterLineMetadata();
        }
        while (!this.lineReloaded) {
          await new Promise((resolve) => {
            setTimeout(resolve, 100);
          });
        }
        for (const job of this.jobs) {
          if (job.number === jobNumber) {
            this.clickJob(job);
            break;
          }
        }
      }
    },
    closeModalPartSettings() {
      this.modalPartSettings = false;
      this.currentPartFileLoaded = false;
      this.currentPart = {};
    },
    getPrinterLineMetadata() {
      this.parts = [];
      this.jobs = [];
      this.lineData = null;
      this.usesLineMaxSize = false;
      this.usesPrinterMaxSize = false;
      this.usesLineMinimumSpacing = false;
      this.usesPrinterMinimumSpacing = false;
      this.usesLineZStacking = false;
      this.usesPrinterZStacking = false;
      this.editingMaxSize = false;
      this.editingMinimumSpacing = false;
      this.editingPartMinimumSpacing = false;
      if (!this.partUUIDToReload) {
        this.currentPart = {};
        this.currentPartFileLoaded = false;
        this.modalPartSettings = false;
      }
      this.$apiInstance
        .getSupplierPrinterLineMetadata(
          this.$route.params.supplierUUID,
          this.$route.params.printerBrand,
          this.$route.params.printerModel,
          this.materialName
        )
        .then((result) => {
          const tempLineData = {
            unlockedTotalQuantity: 0,
            totalQuantity: 0,
            nestingStatus: 'IDLE',
            maxSize: null,
            minimumSpacing: null,
            nestingStartTime: null,
            zStacking: null,
          };

          tempLineData.nestingStatus = result.nestingStatus;
          tempLineData.maxSize = result.maxSize;
          tempLineData.minimumSpacing = result.minimumSpacing;
          tempLineData.nestingStartTime = result.nestingStartTime;
          tempLineData.nestingEndTime = result.nestingEndTime;
          tempLineData.previousNestingDuration = this.getPreviousNestingDuration(
            result.nestingEndTime,
            result.nestingStartTime
          );
          tempLineData.zStacking = result.zStacking;
          tempLineData.ordersQuickbooksIDs = result.ordersQuickbooksIDs; //remove

          this.ordersMetadata = result.ordersMetadata;

          if (result.maxSize) {
            this.usesLineMaxSize = true;
            this.formMaxHeight = result.maxSize.height;
            this.formMaxWidth = result.maxSize.width;
            this.formMaxDepth = result.maxSize.depth;
          } else {
            this.usesPrinterMaxSize = true;
            this.formMaxHeight = this.printer.build_volume.height;
            this.formMaxWidth = this.printer.build_volume.width;
            this.formMaxDepth = this.printer.build_volume.depth;
          }

          if (result.minimumSpacing) {
            this.usesLineMinimumSpacing = true;
            this.formMinimumSpacing = result.minimumSpacing;
          } else {
            this.usesPrinterMinimumSpacing = true;
            this.formMinimumSpacing = this.printer.minimumSpacing;
          }

          if (result.zStacking !== null) {
            this.usesLineZStacking = true;
            this.formZStacking = result.zStacking;
            this.currentZStacking = result.zStacking;
          } else {
            this.usesPrinterZStacking = true;
            this.formZStacking = this.printer.zStacking;
            this.currentZStacking = this.printer.zStacking;
          }

          for (const key in result.parts) {
            tempLineData.unlockedTotalQuantity +=
              result.parts[key].unlockedQuantity;
            tempLineData.totalQuantity += result.parts[key].quantity;
            this.parts.push(result.parts[key]);
          }

          for (const job of result.jobs) {
            job.minimumDeadline = new Date(job.minimumDeadline);
            job.last_modified = new Date(job.last_modified);
            job.jobName = `Job n°${job.number}`;
            job.nbOfParts = 0;
            job.nbOfLockedParts = 0;
            job.isNew = !job.nestingResultSettings;

            job.errors = [];
            job.assignationQtDict = {};
            for (const productionPart of job.parts) {
              job.nbOfParts += productionPart.quantity;
              if (productionPart.part in job.assignationQtDict) {
                job.assignationQtDict[productionPart.part].nestedQt +=
                  productionPart.quantity;
              } else {
                job.assignationQtDict[productionPart.part] = {
                  nestedQt: productionPart.quantity,
                  assignedQt: 0,
                  partName: productionPart.partName,
                };
              }
            }
            for (const lockedPart of job.lockedParts) {
              job.nbOfLockedParts += lockedPart.quantity;
              if (lockedPart.part in job.assignationQtDict) {
                job.assignationQtDict[lockedPart.part].assignedQt +=
                  lockedPart.quantity;
              } else {
                job.assignationQtDict[lockedPart.part] = {
                  nestedQt: 0,
                  assignedQt: lockedPart.quantity,
                  partName: lockedPart.partName,
                };
              }
            }
            for (const key in job.assignationQtDict) {
              if (
                job.assignationQtDict[key].nestedQt <
                job.assignationQtDict[key].assignedQt
              ) {
                job.errors.push({
                  icon: 'fas fa-link',
                  color: 'warning',
                  messages: [
                    'Au moins une des contraintes d\'assignation n\'est pas respectée.',
                    'Vous pouvez gérer ces contraintes d\'assignation ci-dessous',
                  ],
                });
                break;
              }
            }

            if (
              !job.locked &&
              (result.nestingEndTime + 2000 < result.last_modified ||
                result.nestingEndTime + 2000 < job.last_modified.getTime())
            ) {
              job.errors.push({
                icon: 'fas fa-exchange-alt',
                color: 'warning',
                messages: [
                  'Les paramètres du job ou de la ligne de production ont été modifés depuis le dernier nesting.',
                  'Relancez le nesting pour prendre en compte ces modifications.',
                ],
              });
            }

            if (job.isNew) {
              this.jobs.splice(0, 0, job);
            } else {
              this.jobs.push(job);
            }
          }

          if (this.partUUIDToReload) {
            for (const part of this.parts) {
              if (part.part === this.partUUIDToReload) {
                this.currentPart = part;
                EventBus.$emit('partAssignmentManagerReady');
                EventBus.$emit('printerLineDataReloaded'); //Needed for jobPartsSideLists
                break;
              }
            }
            this.partUUIDToReload = null;
          }

          this.lineData = { ...tempLineData };

          this.lineReloaded = true;

          if (this.lineData.nestingStatus === 'IN_PROGRESS') {
            this.updateTimer();
            setTimeout(this.checkNestingStatus, 5000, this.materialName);
          }
        });
    },
    getPartViewer3dFile(part) {
      const partUUID = part.part;
      if (part.reference === 'ORDER') {
        const oReq = DownloadSupplierPartViewer3dFile.downloadSupplierPartViewer3dFile(
          this.$apiInstance,
          this.$route.params.supplierUUID,
          partUUID
        );
        oReq.onloadend = () => {
          this.viewer3dFiles[partUUID] = {
            extension: 'blsv',
            buffer: Buffer.from(oReq.response)
          };
          this.currentPartFileLoaded = true;
        };
      } else {
        const oReq = DownloadSupplierInternalPartViewer3dFile.downloadSupplierInternalPartViewer3dFile(
          this.$apiInstance,
          this.$route.params.supplierUUID,
          partUUID
        );
        oReq.onloadend = () => {
          this.viewer3dFiles[partUUID] = {
            extension: 'blsv',
            buffer: Buffer.from(oReq.response)
          };
          this.currentPartFileLoaded = true;
        };
      }
    },
    forceRerender() {
      this.renderKey += 1;
    },
    forceSpecificRerender(materialInfos) {
      materialInfos.renderKey = `${materialInfos.materialName}_${
        parseInt(materialInfos.renderKey.split('_')[1]) + 1
      }`;
    },
    getDeadlineColor(deadline) {
      if (deadline.getTime() === 0) {
        return '#3e555e';
      }

      const now = new Date();
      const lowestDeadline = 1; //Days = red
      const largestDeadline = 14; //Days = green
      const oneDay = 1000 * 60 * 60 * 24;
      const remainingDays = Math.min(
        largestDeadline,
        Math.max(
          lowestDeadline,
          Math.round((deadline.getTime() - now.getTime()) / oneDay)
        )
      );

      if (remainingDays >= 5) {
        return '#169f85';
      } else if (remainingDays >= 3) {
        return '#ffb000';
      } else {
        return '#d9534f';
      }
    },
    getFormattedDeadline(deadline) {
      if (deadline.getTime() === 0) {
        return ' - / - / - ';
      }
      return (
        deadline.getDate() +
        ' / ' +
        (deadline.getMonth() + 1) +
        ' / ' +
        deadline.getFullYear()
      );
    },
    startNesting() {
      if (this.nestingStatus !== 'IN_PROGRESS') {
        const supplierUUID = this.$route.params.supplierUUID;
        const printerBrand = this.$route.params.printerBrand;
        const printerModel = this.$route.params.printerModel;
        this.$apiInstance
          .nestSupplierPrinterLineParts(
            supplierUUID,
            printerBrand,
            printerModel,
            this.materialName
          )
          .then((response) => {})
          .finally(() => {
            this.forceRerender();
            this.getPrinterLineMetadata();
            this.updateTimer();
          });
      }
    },
    checkNestingStatus() {
      // In case the user left the page, $route.params.printerBrand & printerModel will change
      if (
        this.printerBrand === this.$route.params.printerBrand &&
        this.printerModel === this.$route.params.printerModel
      ) {
        this.$apiInstance
          .getSupplierPrinterLineNestingStatus(
            this.$route.params.supplierUUID,
            this.$route.params.printerBrand,
            this.$route.params.printerModel,
            this.materialName
          )
          .then((data) => {
            this.nestingStatus = data;
            if (this.nestingStatus !== 'IN_PROGRESS') {
              this.getPrinterLineMetadata();
              this.forceRerender();
            } else {
              setTimeout(this.checkNestingStatus, 5000, this.materialName);
            }
          });
      }
    },
    updateTimer() {
      if (
        this.lineData &&
        this.lineData.nestingStatus === 'IN_PROGRESS' &&
        this.printerBrand === this.$route.params.printerBrand &&
        this.printerModel === this.$route.params.printerModel
      ) {
        const now = Date.now();
        let difference = now - this.lineData.nestingStartTime;
        const minutes = Math.floor(difference / (1000 * 60));
        difference = difference - minutes * 1000 * 60;
        const secondes = Math.floor(difference / 1000);
        this.nestingUpTime =
          '' + minutes + ':' + (secondes < 10 ? '0' + secondes : secondes);
        setTimeout(this.updateTimer, 1000);
      }
    },
    getPreviousNestingDuration(endTime, startTime) {
      if (endTime) {
        let difference = endTime - startTime;
        const minutes = Math.floor(difference / (1000 * 60));
        difference = difference - minutes * 1000 * 60;
        const secondes = Math.floor(difference / 1000);
        return '' + minutes + ':' + (secondes < 10 ? '0' + secondes : secondes);
      } else {
        return null;
      }
    },
    changeSettingsVisibility() {
      this.showLineSettings = !this.showLineSettings;
    },
    editMaxSize() {
      this.editingMaxSize = true;
    },
    validateEditSize() {
      const modifySupplierPrinterLineSettingsBody = new this.$BcmModel.ModifySupplierPrinterLineSettingsBody();

      const printerBuildVolume = new this.$BcmModel.PrinterBuildVolume();
      printerBuildVolume.width = parseInt(this.formMaxWidth);
      printerBuildVolume.depth = parseInt(this.formMaxDepth);
      printerBuildVolume.height = parseInt(this.formMaxHeight);
      modifySupplierPrinterLineSettingsBody.maxSize = printerBuildVolume;

      if (this.usesLineMinimumSpacing) {
        modifySupplierPrinterLineSettingsBody.minimumSpacing = this.lineData.minimumSpacing;
      }

      if (this.usesLineZStacking) {
        modifySupplierPrinterLineSettingsBody.zStacking = this.lineData.zStacking;
      }

      this.loadingForm = true;
      this.$apiInstance
        .modifySupplierPrinterLineSettings(
          this.$route.params.supplierUUID,
          this.$route.params.printerBrand,
          this.$route.params.printerModel,
          this.materialName,
          modifySupplierPrinterLineSettingsBody
        )
        .then(
          () => {
            this.$notification.notify('SUCCESS',this.$t('LineMaxSizeUpdated', {
              material: this.materialName,
            }));
            this.getPrinterLineMetadata();
          },
          (error) => {
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.loadingForm = false;
        });
    },
    cancelEditSize() {
      if (this.usesLineMaxSize) {
        this.formMaxHeight = this.lineData.maxSize.height;
        this.formMaxWidth = this.lineData.maxSize.width;
        this.formMaxDepth = this.lineData.maxSize.depth;
      } else {
        this.formMaxHeight = this.printer.build_volume.height;
        this.formMaxWidth = this.printer.build_volume.width;
        this.formMaxDepth = this.printer.build_volume.depth;
      }
      this.editingMaxSize = false;
    },
    resetSize() {
      const modifySupplierPrinterLineSettingsBody = new this.$BcmModel.ModifySupplierPrinterLineSettingsBody();

      if (this.usesLineMinimumSpacing) {
        modifySupplierPrinterLineSettingsBody.minimumSpacing = this.lineData.minimumSpacing;
      }

      if (this.usesLineZStacking) {
        modifySupplierPrinterLineSettingsBody.zStacking = this.lineData.zStacking;
      }

      this.loadingForm = true;
      this.$apiInstance
        .modifySupplierPrinterLineSettings(
          this.$route.params.supplierUUID,
          this.$route.params.printerBrand,
          this.$route.params.printerModel,
          this.materialName,
          modifySupplierPrinterLineSettingsBody
        )
        .then(
          () => {
            this.$notification.notify('SUCCESS',tthis.$t('LineMaxSizeReset', {
              material: this.materialName,
            }));
            this.getPrinterLineMetadata();
          },
          (error) => {
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.loadingForm = false;
        });
    },
    editMinimumSpacing() {
      this.editingMinimumSpacing = true;
    },
    validateEditMinimumSpacing() {
      const modifySupplierPrinterLineSettingsBody = new this.$BcmModel.ModifySupplierPrinterLineSettingsBody();

      if (this.usesLineMaxSize) {
        const printerBuildVolume = new this.$BcmModel.PrinterBuildVolume();
        printerBuildVolume.width = this.lineData.maxSize.width;
        printerBuildVolume.depth = this.lineData.maxSize.depth;
        printerBuildVolume.height = this.lineData.maxSize.height;
        modifySupplierPrinterLineSettingsBody.maxSize = printerBuildVolume;
      }

      if (this.usesLineZStacking) {
        modifySupplierPrinterLineSettingsBody.zStacking = this.lineData.zStacking;
      }

      modifySupplierPrinterLineSettingsBody.minimumSpacing = parseInt(
        this.formMinimumSpacing
      );

      this.loadingForm = true;
      this.$apiInstance
        .modifySupplierPrinterLineSettings(
          this.$route.params.supplierUUID,
          this.$route.params.printerBrand,
          this.$route.params.printerModel,
          this.materialName,
          modifySupplierPrinterLineSettingsBody
        )
        .then(
          () => {
            this.$notification.notify('SUCCESS',tthis.$t('LineMinSpacingUpdated', {
              material: this.materialName,
            }));
            this.getPrinterLineMetadata();
          },
          (error) => {
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.loadingForm = false;
        });
    },
    cancelEditMinimumSpacing() {
      if (this.usesLineMinimumSpacing) {
        this.formMinimumSpacing = this.lineData.minimumSpacing;
      } else {
        this.formMinimumSpacing = this.printer.minimumSpacing;
      }
      this.editingMinimumSpacing = false;
    },
    resetMinimumSpacing() {
      const modifySupplierPrinterLineSettingsBody = new this.$BcmModel.ModifySupplierPrinterLineSettingsBody();

      if (this.usesLineMaxSize) {
        const printerBuildVolume = new this.$BcmModel.PrinterBuildVolume();
        printerBuildVolume.width = this.lineData.maxSize.width;
        printerBuildVolume.depth = this.lineData.maxSize.depth;
        printerBuildVolume.height = this.lineData.maxSize.height;
        modifySupplierPrinterLineSettingsBody.maxSize = printerBuildVolume;
      }

      if (this.usesLineZStacking) {
        modifySupplierPrinterLineSettingsBody.zStacking = this.lineData.zStacking;
      }

      this.loadingForm = true;
      this.$apiInstance
        .modifySupplierPrinterLineSettings(
          this.$route.params.supplierUUID,
          this.$route.params.printerBrand,
          this.$route.params.printerModel,
          this.materialName,
          modifySupplierPrinterLineSettingsBody
        )
        .then(
          () => {
            this.$notification.notify('SUCCESS',tthis.$t('LineMinSpacingReset', {
              material: this.materialName,
            }));
            this.getPrinterLineMetadata();
          },
          (error) => {
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.loadingForm = false;
        });
    },
    validateEditZStacking() {
      const modifySupplierPrinterLineSettingsBody = new this.$BcmModel.ModifySupplierPrinterLineSettingsBody();

      if (this.usesLineMaxSize) {
        const printerBuildVolume = new this.$BcmModel.PrinterBuildVolume();
        printerBuildVolume.width = this.lineData.maxSize.width;
        printerBuildVolume.depth = this.lineData.maxSize.depth;
        printerBuildVolume.height = this.lineData.maxSize.height;
        modifySupplierPrinterLineSettingsBody.maxSize = printerBuildVolume;
      }

      if (this.usesLineMinimumSpacing) {
        modifySupplierPrinterLineSettingsBody.minimumSpacing = this.lineData.minimumSpacing;
      }

      modifySupplierPrinterLineSettingsBody.zStacking = this.formZStacking;

      this.loadingForm = true;
      this.$apiInstance
        .modifySupplierPrinterLineSettings(
          this.$route.params.supplierUUID,
          this.$route.params.printerBrand,
          this.$route.params.printerModel,
          this.materialName,
          modifySupplierPrinterLineSettingsBody
        )
        .then(
          (response) => {
            this.getPrinterLineMetadata();
          },
          (error) => {
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.loadingForm = false;
        });
    },
    initShowPartSettings(part) {
      this.modalPartSettings = true;
      this.currentPart = part;
      if (!this.viewer3dFiles[this.currentPart.part]) {
        this.getPartViewer3dFile(this.currentPart);
      } else {
        this.currentPartFileLoaded = true;
      }
    },
    closeDialogPart() {
      if (!this.modalPartSettings) {
        this.currentPartFileLoaded = false;
        this.currentPart = {};
        this.editingPartMinimumSpacing = false;
        this.formPartMinimumSpacing = 0;
      }
    },
    addEmptyJob() {
      this.loadingForm = true;
      this.$apiInstance
        .addSupplierPrinterLineEmptyJob(
          this.$route.params.supplierUUID,
          this.$route.params.printerBrand,
          this.$route.params.printerModel,
          this.materialName
        )
        .then(() => {
          this.getPrinterLineMetadata();
          this.forceRerender();
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
  },
};
</script>
