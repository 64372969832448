var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-archivesDashboard fill-height"
  }, [_c('v-card-text', {
    attrs: {
      "height": "80%"
    }
  }, [_c('v-row', {
    staticClass: "mx-0",
    attrs: {
      "height": "100%"
    }
  }, [_c('v-col', {
    staticClass: "fill-height",
    attrs: {
      "cols": _vm.$vuetify.breakpoint.smAndDown ? 12 : 3
    }
  }, [_c('v-card', {
    staticClass: "mb-4"
  }, [_c('v-card-title', {
    staticClass: "px-4 py-2 title-card"
  }, [_c('span', {
    staticClass: "white--text"
  }, [_c('v-icon', {
    attrs: {
      "color": "white"
    }
  }, [_vm._v("fas fa-sort")]), _vm._v(" " + _vm._s(_vm.$t("Sort")) + " ")], 1), _c('v-spacer')], 1), _c('v-divider'), _c('v-card-text', [_c('v-row', {
    staticClass: "mx-0"
  }, [_c('v-select', {
    attrs: {
      "label": _vm.$t('SortBy'),
      "items": _vm.formSortByItems
    },
    on: {
      "change": _vm.sortFilteredJobs
    },
    scopedSlots: _vm._u([{
      key: "append-outer",
      fn: function fn() {
        return [_c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c('v-btn', _vm._g({
                staticClass: "ml-2",
                attrs: {
                  "color": "info"
                },
                on: {
                  "click": _vm.reverseSort
                }
              }, on), [_vm.currentSortAscending ? _c('v-icon', [_vm._v(" fas fa-sort-amount-down-alt ")]) : _c('v-icon', [_vm._v(" fas fa-sort-amount-down ")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("ReverseSort")))])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formSortBy,
      callback: function callback($$v) {
        _vm.formSortBy = $$v;
      },
      expression: "formSortBy"
    }
  })], 1)], 1)], 1), _c('v-card', [_c('v-card-title', {
    staticClass: "px-4 py-2 title-card"
  }, [_c('span', {
    staticClass: "white--text"
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "color": "white"
    }
  }, [_vm._v("fas fa-filter")]), _vm._v(" " + _vm._s(_vm.$t("Filters")) + " ")], 1), _c('v-spacer')], 1), _c('v-divider'), _c('v-card-text', [_c('v-row', {
    staticClass: "mx-0"
  }, [_c('v-select', {
    attrs: {
      "label": _vm.$t('Material(s)'),
      "items": _vm.formMaterialsItems,
      "item-value": "value",
      "item-text": "text",
      "clearable": "",
      "multiple": ""
    },
    on: {
      "change": _vm.updateFilteredJobs
    },
    model: {
      value: _vm.formMaterials,
      callback: function callback($$v) {
        _vm.formMaterials = $$v;
      },
      expression: "formMaterials"
    }
  })], 1), _c('v-row', {
    staticClass: "mx-0"
  }, [_c('v-col', {
    staticClass: "pl-0 pr-4",
    attrs: {
      "cols": _vm.$vuetify.breakpoint.md ? 12 : 6
    }
  }, [_c('v-menu', {
    ref: "formMenuDateMin",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": _vm.$t('DateMinimum'),
            "readonly": "",
            "clearable": ""
          },
          on: {
            "click:clear": function clickClear($event) {
              _vm.formDateMin = null;

              _vm.updateFilteredJobs();
            }
          },
          model: {
            value: _vm.formDateMin,
            callback: function callback($$v) {
              _vm.formDateMin = $$v;
            },
            expression: "formDateMin"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.formMenuDateMin,
      callback: function callback($$v) {
        _vm.formMenuDateMin = $$v;
      },
      expression: "formMenuDateMin"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "show-current": false
    },
    on: {
      "input": function input($event) {
        _vm.formMenuDateMin = false;

        _vm.updateFilteredJobs();
      }
    },
    model: {
      value: _vm.formDateMin,
      callback: function callback($$v) {
        _vm.formDateMin = $$v;
      },
      expression: "formDateMin"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "pr-0",
    class: {
      'pl-4': !_vm.$vuetify.breakpoint.md,
      'pr-0': !_vm.$vuetify.breakpoint.md,
      'pl-0': _vm.$vuetify.breakpoint.md
    },
    attrs: {
      "cols": _vm.$vuetify.breakpoint.md ? 12 : 6
    }
  }, [_c('v-menu', {
    ref: "formMenuDateMax",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on,
            attrs = _ref3.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": _vm.$t('DateMaximum'),
            "readonly": "",
            "clearable": ""
          },
          on: {
            "click:clear": function clickClear($event) {
              _vm.formDateMax = null;

              _vm.updateFilteredJobs();
            }
          },
          model: {
            value: _vm.formDateMax,
            callback: function callback($$v) {
              _vm.formDateMax = $$v;
            },
            expression: "formDateMax"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.formMenuDateMax,
      callback: function callback($$v) {
        _vm.formMenuDateMax = $$v;
      },
      expression: "formMenuDateMax"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "show-current": false
    },
    on: {
      "input": function input($event) {
        _vm.formMenuDateMax = false;

        _vm.updateFilteredJobs();
      }
    },
    model: {
      value: _vm.formDateMax,
      callback: function callback($$v) {
        _vm.formDateMax = $$v;
      },
      expression: "formDateMax"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "fill-height",
    attrs: {
      "cols": _vm.$vuetify.breakpoint.smAndDown ? 12 : 9
    }
  }, [_c('v-card', {
    staticClass: "card-archived-jobs scrollbar"
  }, [_c('v-card-title', {
    staticClass: "title-card px-10 py-3"
  }, [_c('span', {
    staticClass: "white--text"
  }, [_vm._v(" " + _vm._s(_vm.archivedJobs.length) + " " + _vm._s(_vm.$t("JobsArchived")) + " ")]), _vm.formMaterials.length > 0 || _vm.formDateMin || _vm.formDateMax ? _c('span', {
    staticClass: "white--text ml-1"
  }, [_vm._v(" " + _vm._s(' - ') + " " + _vm._s(_vm.filteredJobs.length) + " " + _vm._s(_vm.$t("JobsCorrespondFilters")) + " ")]) : _vm._e()]), _c('v-card-text', {
    staticClass: "d-flex flex-wrap pt-0 pl-0 pb-6 pr-6"
  }, _vm._l(_vm.filteredJobs, function (job, index) {
    return _c('v-card', {
      key: job.key + index,
      staticClass: "mt-6 ml-6 card-job",
      attrs: {
        "height": "250px",
        "width": "200px"
      },
      on: {
        "click": function click($event) {
          return _vm.clickJob(job);
        }
      }
    }, [_c('v-card-title', {
      staticClass: "pa-2 info"
    }, [_c('span', {
      staticClass: "white--text body-1"
    }, [_vm._v("Job n°" + _vm._s(job.number))]), _c('v-spacer'), _c('span', {
      staticClass: "orange--text body-1"
    }, [_vm._v(_vm._s(_vm.$t(job.material)))])], 1), _c('v-card-text', {
      staticClass: "px-0"
    }, [_c('v-row', {
      staticClass: "mt-5 mb-4 align-center",
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      staticClass: "py-0 px-1 text-center",
      attrs: {
        "height": "100%",
        "cols": "3",
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-row', {
      staticClass: "mx-1",
      attrs: {
        "align-center": "",
        "justify-center": "",
        "fill-height": ""
      }
    }, [_c('v-img', {
      attrs: {
        "src": require("../../../../public/img/icons/PlanningDark.png"),
        "max-width": "36",
        "max-height": "36",
        "width": "36",
        "height": "36"
      }
    })], 1)], 1), _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "9"
      }
    }, [_c('div', [_c('span', {
      staticClass: "font-weight-bold mr-1"
    }, [_vm._v(" " + _vm._s(job.nbOfParts) + " ")]), job.nbOfParts > 1 ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("PlacedParts")) + " ")]) : _c('span', [_vm._v(_vm._s(_vm.$t("PlacedPart")))])])])], 1), _c('v-divider'), _c('v-row', {
      staticClass: "mb-3 align-center",
      attrs: {
        "no-gutters": ""
      }
    }, [_c('div', {
      staticClass: "layout align-center justify-center"
    }, [_c('span', {
      staticClass: "heightCubeJobCard"
    }, [_vm._v(" " + _vm._s(job.nestingSize.height.toFixed(0)) + " ")]), _c('span', {
      staticClass: "widthCubeJobCard"
    }, [_vm._v(" " + _vm._s(job.nestingSize.width.toFixed(0)) + " ")]), _c('span', {
      staticClass: "depthCubeJobCard"
    }, [_vm._v(" " + _vm._s(job.nestingSize.depth.toFixed(0)) + " ")]), _c('v-img', {
      staticClass: "imageCube",
      attrs: {
        "src": require("../../../../public/img/icons/cube_size_arrows-svg.svg"),
        "alt": "cube sizes",
        "max-width": "90px",
        "max-height": "90px"
      }
    })], 1)]), _c('v-divider'), _c('v-row', {
      staticClass: "ma-3 mt-2 align-center",
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-icon', {
      attrs: {
        "size": "30",
        "color": "info"
      }
    }, [_vm._v(" fas fa-file-archive ")]), _c('span', {
      staticClass: "layout align-center justify-center"
    }, [_vm._v(" " + _vm._s(_vm.getFormattedDeadline(job.last_modified)) + " ")])], 1)], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1), _vm.jobSelected ? _c('JobDashboard', {
    attrs: {
      "job-metadata": _vm.jobSelected,
      "line-metadata": _vm.lineSelected,
      "orders-metadata": _vm.ordersMetadata,
      "job-archived": true
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }