<template lang="html" src="./planningDashboard.template.vue"></template>

<style lang="scss" src="./planningDashboard.scss"></style>

<script>
import JobDashboard from './../../jobDashboard/JobDashboard';
import PlanningLineDashboard from './planningLineDashboard/PlanningLineDashboard';

const i18nData = require('./planningDashboard.i18n');

export default {
  name: 'PlanningDashboard',
  components: {
    JobDashboard,
    PlanningLineDashboard,
  },
  props: {
    printer: {
      type: Object,
      required: true,
    },
    materials: {
      type: Array,
      required: true,
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
