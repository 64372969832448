<template lang="html" src="./productionDashboard.template.vue"></template>

<style lang="scss" src="./productionDashboard.scss"></style>

<script>
import { EventBus } from '@cloudmanufacturingtechnologies/portal-components';
import JobDashboard from '../../jobDashboard/JobDashboard';

const i18nData = require('./productionDashboard.i18n');

export default {
  name: 'ProductionDashboard',
  components: {
    JobDashboard,
  },
  props: {
    printer: {
      type: Object,
      required: true,
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      ordersMetadata: {},
      lineData: {},
      jobs: [],
      jobsReloaded: false,
      jobSelected: null,
      lineSelected: null,
    };
  },
  created() {
    EventBus.$on('closeJobDashboard', () => {
      this.jobSelected = null;
    });
    EventBus.$on('reloadJob', this.reloadJob);
    this.getProductionJobsMetadata();
  },
  mounted() {},
  beforeDestroy() {
    EventBus.$off('reloadJob', this.reloadJob);
  },
  methods: {
    getProductionJobsMetadata() {
      this.jobs = [];
      this.$apiInstance
        .getSupplierPrinterLinesProductionMetadata(
          this.$route.params.supplierUUID,
          this.$route.params.printerBrand,
          this.$route.params.printerModel
        )
        .then((data) => {
          for (const job of data.jobs) {
            job.errors = [];
            job.minimumDeadline = new Date(job.minimumDeadline);
            job.nbOfParts = 0;
            for (const productionPart of job.parts) {
              job.nbOfParts += productionPart.quantity;
            }

            this.jobs.push(job);
          }

          this.ordersMetadata = data.ordersMetadata;

          this.lineData = {
            printer: this.printer,
          };
          this.jobsReloaded = true;
        });
    },
    getDeadlineColor(deadline) {
      if (deadline.getTime() === 0) {
        return '#3e555e';
      }

      const now = new Date();
      const lowestDeadline = 1; //Days = red
      const largestDeadline = 14; //Days = green
      const oneDay = 1000 * 60 * 60 * 24;
      const remainingDays = Math.min(
        largestDeadline,
        Math.max(
          lowestDeadline,
          Math.round((deadline.getTime() - now.getTime()) / oneDay)
        )
      );

      if (remainingDays >= 5) {
        return '#169f85';
      } else if (remainingDays >= 3) {
        return '#ffb000';
      } else {
        return '#d9534f';
      }
    },
    getFormattedDeadline(deadline) {
      if (deadline.getTime() === 0) {
        return ' - / - / - ';
      }
      return (
        deadline.getDate() +
        ' / ' +
        (deadline.getMonth() + 1) +
        ' / ' +
        deadline.getFullYear()
      );
    },
    async reloadJob(material, jobNumber) {
      while (!this.jobsReloaded) {
        await new Promise((resolve) => {
          setTimeout(resolve, 100);
        });
      }
      for (const job of this.jobs) {
        if (job.number === jobNumber && job.material === material) {
          this.clickJob(job);
          break;
        }
      }
    },
    clickJob(job) {
      const line = {
        jobs: this.jobs,
        parts: job.parts,
        lineData: this.lineData,
        material: job.material,
        printer: this.printer,
      };
      this.jobSelected = job;
      this.lineSelected = line;
      EventBus.$emit('updateJobParameters');

      setTimeout(() => {
        EventBus.$emit('printerLineDataReloaded');
      }, 0);
    },
  },
};
</script>
