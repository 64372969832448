var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-productionDashboard"
  }, [_c('v-card-text', {
    staticClass: "layout justify-space-around flex-wrap pt-4"
  }, _vm._l(_vm.printer.quantity, function (i) {
    return _c('v-card', {
      key: i,
      staticClass: "ma-4 card-printer",
      attrs: {
        "width": "300px",
        "max-height": "350px"
      }
    }, [_c('v-card-title', {
      staticClass: "py-3 px-2 white--text card-printer-title"
    }, [_vm._v(" " + _vm._s(_vm.$t("Printer")) + " n° " + _vm._s(i) + " "), _c('v-spacer'), _vm.jobs[i - 1] ? _c('span', {
      staticClass: "mr-2"
    }, [_vm._v(_vm._s(_vm.$t(_vm.jobs[i - 1].material)))]) : _vm._e()], 1), _c('v-card-text', {
      staticClass: "pt-3"
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12",
        "align": "center"
      }
    }, [_c('v-row', {
      staticClass: "d-flex mx-n2",
      attrs: {
        "justify": "center"
      }
    }, [_vm.jobs[i - 1] ? _c('v-card', {
      staticClass: "ma-4",
      attrs: {
        "height": "250px",
        "width": "200px"
      },
      on: {
        "click": function click($event) {
          return _vm.clickJob(_vm.jobs[i - 1]);
        }
      }
    }, [_c('v-card-title', {
      staticClass: "pa-2 info"
    }, [_c('span', {
      staticClass: "white--text body-1"
    }, [_vm._v(" Job n°" + _vm._s(_vm.jobs[i - 1].number) + " ")]), _c('v-spacer'), _c('v-icon', {
      staticClass: "mr-1",
      attrs: {
        "small": "",
        "color": "orange"
      }
    }, [_vm._v(" fas fa-lock ")])], 1), _c('v-card-text', {
      staticClass: "px-0"
    }, [_c('v-row', {
      staticClass: "my-3 align-center",
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      staticClass: "py-0 text-center",
      attrs: {
        "cols": "3",
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-layout', {
      attrs: {
        "row": "",
        "wrap": "",
        "align-center": "",
        "justify-center": "",
        "fill-height": ""
      }
    }, [_c('v-img', {
      attrs: {
        "src": require("../../../../public/img/icons/PlanningDark.png"),
        "max-width": "36",
        "max-height": "36",
        "width": "36",
        "height": "36"
      }
    })], 1)], 1), _c('v-col', {
      staticClass: "py-0 px-2 pl-3",
      attrs: {
        "cols": "9"
      }
    }, [_c('div', [_c('span', {
      staticClass: "font-weight-bold mr-1"
    }, [_vm._v(" " + _vm._s(_vm.jobs[i - 1].nbOfParts) + " ")]), _vm.jobs[i - 1].nbOfParts > 1 ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("PlacedParts")) + " ")]) : _c('span', [_vm._v(_vm._s(_vm.$t("PlacedPart")))])])])], 1), _c('v-divider'), _c('v-row', {
      staticClass: "mb-4 mt-1 mx-0 px-2"
    }, [_c('div', {
      staticClass: "layout align-center justify-center"
    }, [_c('span', {
      staticClass: "heightCubeJobCard"
    }, [_vm._v(" " + _vm._s(_vm.jobs[i - 1].nestingSize.height.toFixed(0)) + " ")]), _c('span', {
      staticClass: "widthCubeJobCard"
    }, [_vm._v(" " + _vm._s(_vm.jobs[i - 1].nestingSize.width.toFixed(0)) + " ")]), _c('span', {
      staticClass: "depthCubeJobCard"
    }, [_vm._v(" " + _vm._s(_vm.jobs[i - 1].nestingSize.depth.toFixed(0)) + " ")]), _c('v-img', {
      staticClass: "imageCube",
      attrs: {
        "src": require("../../../../public/img/icons/cube_size_arrows-svg.svg"),
        "alt": "cube sizes",
        "max-width": "90px",
        "max-height": "90px"
      }
    })], 1)]), _c('v-divider'), _c('v-row', {
      staticClass: "my-2 mx-0 px-2"
    }, [_c('v-icon', {
      attrs: {
        "size": "30",
        "color": _vm.getDeadlineColor(_vm.jobs[i - 1].minimumDeadline)
      }
    }, [_vm._v(" fas fa-calendar-alt ")]), _c('span', {
      staticClass: "layout align-center justify-center"
    }, [_vm._v(" " + _vm._s(_vm.getFormattedDeadline(_vm.jobs[i - 1].minimumDeadline)) + " ")])], 1)], 1)], 1) : _c('v-card', {
      staticClass: "my-2 empty-card-job",
      attrs: {
        "height": "250px",
        "width": "200px"
      }
    }, [_c('v-card-text', {
      staticClass: "fill-height d-flex justify-center align-center"
    }, [_c('span', {
      staticClass: "body-1"
    }, [_vm._v(_vm._s(_vm.$t("Available")))])])], 1)], 1)], 1)], 1)], 1)], 1);
  }), 1), _vm.jobSelected ? _c('JobDashboard', {
    attrs: {
      "job-metadata": _vm.jobSelected,
      "line-metadata": _vm.lineSelected,
      "orders-metadata": _vm.ordersMetadata
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }