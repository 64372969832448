var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-jobDashboard no-rounded-corner"
  }, [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "fullscreen": "",
      "no-click-animation": "",
      "content-class": "dialog-job page-jobDashboard"
    },
    model: {
      value: _vm.fullscreen,
      callback: function callback($$v) {
        _vm.fullscreen = $$v;
      },
      expression: "fullscreen"
    }
  }, [_c('v-card', {
    staticClass: "scrollBar no-rounded-corner"
  }, [_c('v-card-text', {
    staticClass: "px-0 mb-4"
  }, [_c('JobDashboardToolbar', {
    attrs: {
      "job-metadata": _vm.jobMetadata,
      "line-metadata": _vm.lineMetadata,
      "job-placements": _vm.jobPlacements
    }
  }), _c('div', {
    staticClass: "pt-5 px-5"
  }, [_vm.$vuetify.breakpoint.lgAndUp ? _c('JobDashboardParameters', {
    staticClass: "mb-5",
    attrs: {
      "job-metadata": _vm.jobMetadata,
      "line-metadata": _vm.lineMetadata
    }
  }) : _vm._e(), _vm.partsDownloaded ? _c('JobViewer', {
    attrs: {
      "job-placements": _vm.jobPlacements,
      "parts-files": _vm.supplierParts,
      "printer-size": _vm.printerSize,
      "line-jobs-metadata": _vm.lineMetadata.jobs,
      "line-parts-metadata": _vm.lineMetadata.parts,
      "production-job": _vm.jobMetadata,
      "orders-metadata": _vm.ordersMetadata,
      "material-name": _vm.lineMetadata.material
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }