var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-PlanningDashboard"
  }, [_vm.materials.length > 0 ? _c('v-card-text', {
    staticClass: "pt-8"
  }, _vm._l(_vm.materials, function (materialName, index) {
    return _c('PlanningLineDashboard', {
      key: materialName + index,
      staticClass: "mb-6",
      attrs: {
        "material-name": materialName,
        "printer": _vm.printer
      }
    });
  }), 1) : _vm._e(), _vm.printer && _vm.materials.length === 0 ? _c('v-card-text', {
    staticClass: "pt-12 d-flex justify-center"
  }, [_c('v-card', {
    staticClass: "mt-12 elevation-4",
    attrs: {
      "width": "20%"
    }
  }, [_c('v-card-title', {
    staticClass: "justify-center"
  }, [_c('v-icon', {
    attrs: {
      "large": "",
      "color": "info"
    }
  }, [_vm._v(" fas fa-info ")])], 1), _c('v-divider'), _c('v-card-text', [_c('p', {
    staticClass: "body-1 text-justify"
  }, [_vm._v(" " + _vm._s(_vm.$t("NoProductionLineText1")) + " ")]), _c('p', {
    staticClass: "body-1 text-justify"
  }, [_vm._v(" " + _vm._s(_vm.$t("NoProductionLineText2")) + " ")])])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }