var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-jobDashboardToolbar"
  }, [_c('v-toolbar', {
    staticClass: "menuBackground"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "outlined": "",
      "color": "white"
    },
    on: {
      "click": _vm.closeJobDashboard
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-arrow-left ")]), _vm._v(" " + _vm._s(_vm.$t("GoBack")) + " ")], 1)], 1), _c('v-col', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-toolbar-title', {
    staticClass: "white--text"
  }, [_vm._v(" Job n° " + _vm._s(_vm.jobMetadata.number) + " - " + _vm._s(_vm.$t(_vm.lineMetadata.material)) + " ")]), _vm.jobMetadata.locked && _vm.jobMetadata.status === 'PLANNING' ? _c('v-btn', {
    staticClass: "ml-2",
    attrs: {
      "small": "",
      "color": "white",
      "outlined": ""
    },
    on: {
      "click": _vm.openJobDialog
    }
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "color": "success"
    }
  }, [_vm._v(" fas fa-lock ")])], 1) : _vm._e(), !_vm.jobMetadata.locked && _vm.jobMetadata.status === 'PLANNING' ? _c('v-btn', {
    staticClass: "ml-2",
    attrs: {
      "small": "",
      "color": "white",
      "outlined": ""
    },
    on: {
      "click": _vm.openJobDialog
    }
  }, [_c('v-icon', {
    attrs: {
      "small": "",
      "color": "warning"
    }
  }, [_vm._v(" fas fa-lock-open ")])], 1) : _vm._e()], 1), _c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "3"
    }
  }, [_vm.jobMetadata.status === 'PRODUCTION' && !_vm.jobPartsDownloaded && _vm.jobPlacements.length > 0 ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "loading": _vm.jobPartsDownloading,
      "color": "info"
    },
    on: {
      "click": _vm.downloadJobPartsFile
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-download ")]), _vm._v(" " + _vm._s(_vm.$t("Download")) + " ")], 1) : _vm._e(), _vm.jobPartsDownloaded ? _c('JobDownloader', {
    attrs: {
      "job-placements": _vm.jobPlacements,
      "parts-files": _vm.jobPartsFiles,
      "parts-extensions": _vm.jobPartsExtensions
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "d-flex justify-end align-center pr-4",
    attrs: {
      "cols": "2"
    }
  }, [_vm.jobMetadata.status === 'PLANNING' ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "orange"
    },
    on: {
      "click": function click($event) {
        return _vm.openJobStatusDialog('PRODUCTION');
      }
    }
  }, [_c('span', {
    staticClass: "white--text"
  }, [_vm._v(_vm._s(_vm.$t("ToProduction")))]), _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "color": "white"
    }
  }, [_vm._v(" fas fa-arrow-right ")])], 1) : _vm._e(), _vm.jobMetadata.status === 'PRODUCTION' ? _c('v-btn', {
    staticClass: "mr-4 text-none",
    attrs: {
      "color": "orange"
    },
    on: {
      "click": function click($event) {
        return _vm.openJobStatusDialog('PLANNING');
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "white"
    }
  }, [_vm._v(" fas fa-arrow-left ")]), _c('span', {
    staticClass: "white--text"
  }, [_vm._v(_vm._s(_vm.$t("ToPlanning")))])], 1) : _vm._e(), _vm.jobMetadata.status === 'PRODUCTION' ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "orange"
    },
    on: {
      "click": function click($event) {
        return _vm.openJobStatusDialog('ARCHIVES');
      }
    }
  }, [_c('span', {
    staticClass: "white--text"
  }, [_vm._v(_vm._s(_vm.$t("ToArchives")))]), _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "color": "white"
    }
  }, [_vm._v(" fas fa-arrow-right ")])], 1) : _vm._e()], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "min-width": "200px",
      "max-width": "350px"
    },
    model: {
      value: _vm.lockJobDialog,
      callback: function callback($$v) {
        _vm.lockJobDialog = $$v;
      },
      expression: "lockJobDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm.jobMetadata.locked ? _c('span', {
    staticClass: "text-justify"
  }, [_vm._v(" " + _vm._s(_vm.$t("unlockJobTitle")) + " ")]) : _c('span', {
    staticClass: "text-justify"
  }, [_vm._v(_vm._s(_vm.$t("lockJobTitle")))])]), _c('v-card-text', {
    staticClass: "my-3 body-1 text-justify"
  }, [_vm.jobMetadata.locked ? _c('span', [_vm._v(_vm._s(_vm.$t("unlockJobText")))]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t("lockJobText")) + " "), _vm.jobMetadata.errors && _vm.jobMetadata.errors.length > 0 ? _c('p', {
    staticClass: "pt-4"
  }, [_c('v-icon', {
    attrs: {
      "color": "warning",
      "small": ""
    }
  }, [_vm._v("fas fa-exclamation-triangle")]), _vm._v(" " + _vm._s(_vm.$t("verifyErrors")) + " ")], 1) : _vm._e()])]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "small": "",
      "color": "error"
    },
    on: {
      "click": _vm.closeJobDialog
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")])], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "small": "",
      "color": "success"
    },
    on: {
      "click": _vm.confirmJobLockAction
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")])], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "min-width": "200px",
      "max-width": "400px"
    },
    model: {
      value: _vm.modifyJobStatusDialog,
      callback: function callback($$v) {
        _vm.modifyJobStatusDialog = $$v;
      },
      expression: "modifyJobStatusDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm.statusModification === 'PLANNING' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("ToPlanningTitle")) + " ")]) : _vm._e(), _vm.statusModification === 'PRODUCTION' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("ToProductionTitle")) + " ")]) : _vm._e(), _vm.statusModification === 'ARCHIVES' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("ToArchivesTitle")) + " ")]) : _vm._e()]), _c('v-divider'), _c('v-card-text', {
    staticClass: "pt-3 text-justify"
  }, [_vm.statusModification === 'PLANNING' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("ToPlanningMessage")) + " ")]) : _vm._e(), _vm.statusModification === 'PRODUCTION' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("ToProductionMessage")) + " "), _vm.jobMetadata.errors.length > 0 ? _c('p', {
    staticClass: "pt-4"
  }, [_c('v-icon', {
    attrs: {
      "color": "warning",
      "small": ""
    }
  }, [_vm._v("fas fa-exclamation-triangle")]), _vm._v(" " + _vm._s(_vm.$t("verifyErrors")) + " ")], 1) : _vm._e()]) : _vm._e(), _vm.statusModification === 'ARCHIVES' ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("ToArchivesMessage")) + " ")]) : _vm._e()]), _c('v-divider'), _c('v-card-actions', {
    staticClass: "py-3"
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "color": "error"
    },
    on: {
      "click": _vm.closeJobStatusDialog
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")])], 1), _c('v-spacer'), _vm.statusModification === 'PLANNING' ? _c('v-btn', {
    attrs: {
      "color": "success",
      "small": ""
    },
    on: {
      "click": _vm.sendJobToPlanning
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")])], 1) : _vm._e(), _vm.statusModification === 'PRODUCTION' ? _c('v-btn', {
    attrs: {
      "color": "success",
      "small": ""
    },
    on: {
      "click": _vm.sendJobToProduction
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")])], 1) : _vm._e(), _vm.statusModification === 'ARCHIVES' ? _c('v-btn', {
    attrs: {
      "color": "success",
      "small": ""
    },
    on: {
      "click": _vm.sendJobToArchives
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")])], 1) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }