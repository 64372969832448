var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-PlanningLineDashboard"
  }, [_vm.lineData ? _c('v-card', [_c('v-card-title', {
    staticClass: "blue-grey darken-3 pa-0"
  }, [_c('v-col', {
    staticClass: "d-flex align-center justify-center",
    attrs: {
      "cols": "2"
    }
  }, [!_vm.showLineSettings ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "outlined": "",
      "color": "white"
    },
    on: {
      "click": _vm.changeSettingsVisibility
    }
  }, [_c('span', {
    staticClass: "white--text mr-3"
  }, [_vm._v(_vm._s(_vm.$t(_vm.materialName)))]), _c('v-icon', {
    attrs: {
      "color": "white"
    }
  }, [_vm._v(" fas fa-cog ")])], 1) : _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "white"
    },
    on: {
      "click": _vm.changeSettingsVisibility
    }
  }, [_c('span', {
    staticClass: "black--text mr-3"
  }, [_vm._v(_vm._s(_vm.$t(_vm.materialName)))]), _c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v(" fas fa-cog ")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-row', {
    staticClass: "align-center",
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [!_vm.showPartsList ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "outlined": "",
      "color": "white"
    },
    on: {
      "click": function click($event) {
        _vm.showPartsList = !_vm.showPartsList;

        _vm.forceRerender();
      }
    }
  }, [_c('span', {
    staticClass: "white--text mr-3"
  }, [_vm._v(" " + _vm._s(_vm.lineData.totalQuantity) + " " + _vm._s(_vm.$t("Parts(Unlocked)")) + " ")]), _c('v-icon', {
    attrs: {
      "color": "white"
    }
  }, [_vm._v(" far fa-eye ")])], 1) : _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "white"
    },
    on: {
      "click": function click($event) {
        _vm.showPartsList = !_vm.showPartsList;

        _vm.forceRerender();
      }
    }
  }, [_c('span', {
    staticClass: "black--text mr-3"
  }, [_vm._v(" " + _vm._s(_vm.lineData.totalQuantity) + " " + _vm._s(_vm.$t("Parts(Unlocked)")) + " ")]), _c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v(" far fa-eye ")])], 1)], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "10"
    }
  }, [_vm.lineData.nestingStatus === 'IN_PROGRESS' ? _c('span', {
    staticClass: "body-1 white--text text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("NestingInProgress")) + " " + _vm._s(_vm.nestingUpTime) + ") ... ")]) : _vm._e(), _vm.lineData.nestingStatus !== 'IN_PROGRESS' && _vm.lineData.previousNestingDuration ? _c('span', {
    staticClass: "body-1 white--text text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("PreviousNestingDoneIn")) + " " + _vm._s(_vm.lineData.previousNestingDuration) + " ")]) : _vm._e()])], 1)], 1), _c('v-col', {
    staticClass: "col-top-right layout align-center justify-space-around",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', {
    staticClass: "align-center black--text text-none",
    attrs: {
      "color": "orange",
      "outlined": "",
      "width": "70%",
      "readonly": _vm.lineData.nestingStatus === 'IN_PROGRESS',
      "disabled": _vm.loadingForm
    },
    on: {
      "click": function click($event) {
        _vm.startNesting();

        _vm.forceRerender();
      }
    }
  }, [_vm.lineData.nestingStatus === 'IN_PROGRESS' ? _c('span', [_vm._v(" Nesting... "), _c('v-icon', {
    staticClass: "ml-1 mt-1",
    attrs: {
      "color": "orange"
    }
  }, [_vm._v("fas fa-spin fa-cog")]), _c('v-icon', {
    staticClass: "ml-n1 mt-n2 fa-spin-reverse"
  }, [_vm._v("fas fa-cog")])], 1) : _c('span', [_vm._v("Nest")])]), _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_vm.lineData.nestingStatus !== 'IN_PROGRESS' ? _c('v-btn', _vm._g({
          attrs: {
            "x-small": "",
            "color": "orange",
            "outlined": "",
            "fab": "",
            "loading": _vm.loadingForm
          },
          on: {
            "click": _vm.addEmptyJob
          }
        }, on), [_c('v-icon', [_vm._v("fas fa-plus")])], 1) : _vm._e()];
      }
    }], null, false, 1771678064)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("AddNewJob")))])])], 1)], 1), _vm.showLineSettings ? _c('v-card-text', {
    staticClass: "px-0 pt-0 line-settings",
    class: {
      'pb-12': _vm.editingMaxSize
    }
  }, [_c('v-row', {
    staticClass: "mx-0 pt-2"
  }, [_c('v-col', {
    staticClass: "px-0",
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "text-center pb-4 d-flex align-center justify-center"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("Workspace")))])]), _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          attrs: {
            "small": ""
          }
        }, 'v-icon', attrs, false), on), [_vm._v(" fas fa-question-circle ")])];
      }
    }], null, false, 3826261577)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("ThisLineUses")))]), _vm.usesLineMaxSize ? _c('span', [_vm._v(_vm._s(_vm.$t("aCustomWorkspace")))]) : _vm._e(), _vm.usesPrinterMaxSize ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("printerWorkspace")) + " ")]) : _vm._e()]), !_vm.editingMaxSize ? _c('v-btn', {
    staticClass: "px-1 ml-2",
    attrs: {
      "color": "warning",
      "x-small": ""
    },
    on: {
      "click": _vm.editMaxSize
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" fas fa-pen ")])], 1) : _vm._e(), _vm.editingMaxSize ? _c('v-btn', {
    staticClass: "px-1 mx-2",
    attrs: {
      "loading": _vm.loadingForm,
      "color": "success",
      "x-small": ""
    },
    on: {
      "click": _vm.validateEditSize
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" fas fa-check ")])], 1) : _vm._e(), _vm.editingMaxSize ? _c('v-btn', {
    staticClass: "px-1 mr-2",
    attrs: {
      "loading": _vm.loadingForm,
      "color": "error",
      "x-small": ""
    },
    on: {
      "click": _vm.cancelEditSize
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" fas fa-times ")])], 1) : _vm._e(), _vm.editingMaxSize ? _c('v-btn', {
    staticClass: "px-1",
    attrs: {
      "loading": _vm.loadingForm,
      "color": "warning",
      "x-small": ""
    },
    on: {
      "click": _vm.resetSize
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" fas fa-redo ")])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "layout align-center justify-center"
  }, [_c('div', [!_vm.editingMaxSize && _vm.usesLineMaxSize ? _c('span', {
    staticClass: "heightCube blue--text font-weight-medium",
    on: {
      "click": _vm.editMaxSize
    }
  }, [_vm._v(" " + _vm._s(_vm.lineData.maxSize.height) + " mm ")]) : _vm._e(), !_vm.editingMaxSize && _vm.usesPrinterMaxSize ? _c('span', {
    staticClass: "heightCube",
    on: {
      "click": _vm.editMaxSize
    }
  }, [_vm._v(" " + _vm._s(_vm.printer.build_volume.height) + " mm ")]) : _vm._e(), !_vm.editingMaxSize && _vm.usesLineMaxSize ? _c('span', {
    staticClass: "widthCube blue--text font-weight-medium",
    on: {
      "click": _vm.editMaxSize
    }
  }, [_vm._v(" " + _vm._s(_vm.lineData.maxSize.width) + " mm ")]) : _vm._e(), !_vm.editingMaxSize && _vm.usesPrinterMaxSize ? _c('span', {
    staticClass: "widthCube",
    on: {
      "click": _vm.editMaxSize
    }
  }, [_vm._v(" " + _vm._s(_vm.printer.build_volume.width) + " mm ")]) : _vm._e(), !_vm.editingMaxSize && _vm.usesLineMaxSize ? _c('span', {
    staticClass: "depthCube blue--text font-weight-medium",
    on: {
      "click": _vm.editMaxSize
    }
  }, [_vm._v(" " + _vm._s(_vm.lineData.maxSize.depth) + " mm ")]) : _vm._e(), !_vm.editingMaxSize && _vm.usesPrinterMaxSize ? _c('span', {
    staticClass: "depthCube",
    on: {
      "click": _vm.editMaxSize
    }
  }, [_vm._v(" " + _vm._s(_vm.printer.build_volume.depth) + " mm ")]) : _vm._e(), _vm.editingMaxSize ? _c('v-form', [_c('span', {
    class: {
      'heightCubeTextField1': String(_vm.formMaxHeight).length <= 1,
      'heightCubeTextField2': String(_vm.formMaxHeight).length === 2,
      'heightCubeTextField3': String(_vm.formMaxHeight).length === 3,
      'heightCubeTextField4': String(_vm.formMaxHeight).length > 3
    }
  }, [_c('v-text-field', {
    class: {
      'textFieldSize1': String(_vm.formMaxHeight).length <= 1,
      'textFieldSize2': String(_vm.formMaxHeight).length === 2,
      'textFieldSize3': String(_vm.formMaxHeight).length === 3,
      'textFieldSize4': String(_vm.formMaxHeight).length > 3
    },
    attrs: {
      "type": "number",
      "rules": _vm.ruleSize,
      "suffix": "mm",
      "solo": "",
      "min": "0"
    },
    on: {
      "input": function input($event) {
        return _vm.checkValue(_vm.formMaxHeight);
      }
    },
    model: {
      value: _vm.formMaxHeight,
      callback: function callback($$v) {
        _vm.formMaxHeight = $$v;
      },
      expression: "formMaxHeight"
    }
  })], 1), _c('span', {
    class: {
      'widthCubeTextField1': String(_vm.formMaxWidth).length <= 1,
      'widthCubeTextField2': String(_vm.formMaxWidth).length === 2,
      'widthCubeTextField3': String(_vm.formMaxWidth).length === 3,
      'widthCubeTextField4': String(_vm.formMaxWidth).length > 3
    }
  }, [_c('v-text-field', {
    class: {
      'textFieldSize1': String(_vm.formMaxWidth).length <= 1,
      'textFieldSize2': String(_vm.formMaxWidth).length === 2,
      'textFieldSize3': String(_vm.formMaxWidth).length === 3,
      'textFieldSize4': String(_vm.formMaxWidth).length > 3
    },
    attrs: {
      "type": "number",
      "rules": _vm.ruleSize,
      "suffix": "mm",
      "solo": "",
      "min": "0"
    },
    model: {
      value: _vm.formMaxWidth,
      callback: function callback($$v) {
        _vm.formMaxWidth = $$v;
      },
      expression: "formMaxWidth"
    }
  })], 1), _c('span', {
    staticClass: "depthCubeTextField1"
  }, [_c('v-text-field', {
    class: {
      'textFieldSize1': String(_vm.formMaxDepth).length <= 1,
      'textFieldSize2': String(_vm.formMaxDepth).length === 2,
      'textFieldSize3': String(_vm.formMaxDepth).length === 3,
      'textFieldSize4': String(_vm.formMaxDepth).length > 3
    },
    attrs: {
      "type": "number",
      "rules": _vm.ruleSize,
      "suffix": "mm",
      "solo": "",
      "min": "0"
    },
    model: {
      value: _vm.formMaxDepth,
      callback: function callback($$v) {
        _vm.formMaxDepth = $$v;
      },
      expression: "formMaxDepth"
    }
  })], 1)]) : _vm._e(), _c('v-img', {
    attrs: {
      "src": require("../../../../../public/img/icons/cube_size_v2.png"),
      "alt": "cube sizes",
      "max-width": "150px",
      "contain": ""
    }
  })], 1)])]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "text-center pb-4 d-flex justify-center align-center"
  }, [_c('h3', {
    staticClass: "mr-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("PartsMinimumSpacing")) + " ")]), _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on,
            attrs = _ref3.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          attrs: {
            "small": ""
          }
        }, 'v-icon', attrs, false), on), [_vm._v(" fas fa-question-circle ")])];
      }
    }], null, false, 3826261577)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("ThisLineUses")))]), _vm.usesLineMinimumSpacing ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("aCustomMinimumSpacing")) + " ")]) : _vm._e(), _vm.usesPrinterMinimumSpacing ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("printerMinimumSpacing")) + " ")]) : _vm._e()]), !_vm.editingMinimumSpacing ? _c('v-btn', {
    staticClass: "px-1 ml-2",
    attrs: {
      "color": "warning",
      "x-small": ""
    },
    on: {
      "click": _vm.editMinimumSpacing
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" fas fa-pen ")])], 1) : _vm._e(), _vm.editingMinimumSpacing ? _c('v-btn', {
    staticClass: "px-1 mx-2",
    attrs: {
      "loading": _vm.loadingForm,
      "color": "success",
      "x-small": ""
    },
    on: {
      "click": _vm.validateEditMinimumSpacing
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" fas fa-check ")])], 1) : _vm._e(), _vm.editingMinimumSpacing ? _c('v-btn', {
    staticClass: "px-1 mr-2",
    attrs: {
      "loading": _vm.loadingForm,
      "color": "error",
      "x-small": ""
    },
    on: {
      "click": _vm.cancelEditMinimumSpacing
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" fas fa-times ")])], 1) : _vm._e(), _vm.editingMinimumSpacing ? _c('v-btn', {
    staticClass: "px-1",
    attrs: {
      "loading": _vm.loadingForm,
      "color": "warning",
      "x-small": ""
    },
    on: {
      "click": _vm.resetMinimumSpacing
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" fas fa-redo ")])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "layout align-center justify-center"
  }, [!_vm.editingMinimumSpacing ? _c('div', {
    on: {
      "click": _vm.editMinimumSpacing
    }
  }, [_vm.usesLineMinimumSpacing ? _c('span', {
    staticClass: "spacingCube blue--text font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.lineData.minimumSpacing) + " mm ")]) : _vm._e(), _vm.usesPrinterMinimumSpacing ? _c('span', {
    staticClass: "spacingCube"
  }, [_vm._v(" " + _vm._s(_vm.printer.minimumSpacing) + " mm ")]) : _vm._e()]) : _vm._e(), _vm.editingMinimumSpacing ? _c('v-text-field', {
    staticClass: "minimumSpacingCubeTextField",
    class: {
      'textFieldSize1': String(_vm.formMinimumSpacing).length <= 1,
      'textFieldSize2': String(_vm.formMinimumSpacing).length === 2,
      'textFieldSize3': String(_vm.formMinimumSpacing).length > 2
    },
    attrs: {
      "type": "number",
      "suffix": "mm",
      "solo": "",
      "min": "1",
      "max": "999"
    },
    model: {
      value: _vm.formMinimumSpacing,
      callback: function callback($$v) {
        _vm.formMinimumSpacing = $$v;
      },
      expression: "formMinimumSpacing"
    }
  }) : _vm._e(), _c('v-img', {
    attrs: {
      "src": require("../../../../../public/img/icons/spacing.png"),
      "alt": "spacing",
      "max-height": "130px",
      "max-width": "400px",
      "contain": ""
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "text-center pb-4 d-flex align-center justify-center"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("VerticalStackingOfParts")))]), _vm.formZStacking !== _vm.currentZStacking ? _c('v-btn', {
    staticClass: "px-1 ml-2",
    attrs: {
      "color": "success",
      "x-small": ""
    },
    on: {
      "click": _vm.validateEditZStacking
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" fas fa-check ")])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "layout align-center justify-center"
  }, [_c('div', {
    staticClass: "switch-zStacking d-flex justify-center align-center"
  }, [_vm.printer.technology === 'MJF' || _vm.printer.technology === 'SLS' ? _c('v-switch', {
    attrs: {
      "color": "info"
    },
    model: {
      value: _vm.formZStacking,
      callback: function callback($$v) {
        _vm.formZStacking = $$v;
      },
      expression: "formZStacking"
    }
  }) : _vm._e(), _vm.formZStacking ? _c('span', [_vm._v(_vm._s(_vm.$t("Allowed")))]) : _vm._e(), !_vm.formZStacking ? _c('span', [_vm._v(_vm._s(_vm.$t("Forbidden")))]) : _vm._e()], 1), _vm.formZStacking ? _c('v-img', {
    attrs: {
      "src": require("../../../../../public/img/icons/zStackingAllowed.png"),
      "alt": "zStacking allowed",
      "max-height": "130px",
      "max-width": "180px",
      "contain": ""
    }
  }) : _c('v-img', {
    attrs: {
      "src": require("../../../../../public/img/icons/zStackingForbidden.png"),
      "alt": "zStacking forbidden",
      "max-height": "130px",
      "max-width": "180px",
      "contain": ""
    }
  })], 1)])], 1)], 1) : _vm._e(), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-row', {
    staticClass: "row-timeline",
    attrs: {
      "no-gutters": ""
    }
  }, [_vm.showPartsList ? _c('v-col', {
    staticClass: "px-0 pa-0 partsTable",
    class: {
      'partsTableSmall': !_vm.expanded
    },
    attrs: {
      "cols": "4"
    }
  }, [_c('v-data-table', {
    staticStyle: {
      "min-height": "100%"
    },
    attrs: {
      "headers": _vm.headersParts,
      "items": _vm.parts,
      "items-per-page": -1,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      },
      "hide-default-footer": "",
      "hide-default-header": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('thead', [_c('tr', [_c('th', {
          staticClass: "px-4",
          attrs: {
            "scope": "col"
          }
        }, [_c('div', [_vm._v(_vm._s(_vm.$t("PartName")))])]), _c('th', {
          staticClass: "px-4",
          attrs: {
            "scope": "col"
          }
        }, [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(_vm.$t("Deadline")) + " ")])]), _c('th', {
          staticClass: "px-4",
          attrs: {
            "scope": "col"
          }
        }, [_c('div', {
          staticClass: "4 d-flex align-center justify-center"
        }, [_vm._v(" " + _vm._s(_vm.$t("Quantities")) + " "), _c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on;
              return [_c('v-icon', _vm._g({
                staticClass: "ml-1",
                attrs: {
                  "small": ""
                }
              }, on), [_vm._v(" fa fa-question-circle ")])];
            }
          }], null, false, 2014302006)
        }, [_c('div', [_c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" Pas encore placé dans un job ")]), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("/")]), _c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" Dans les jobs non vérouillés ")]), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("/")]), _c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" Dans les jobs vérouillés ")])])])], 1)]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_c('div', {
          staticClass: "4 d-flex align-center justify-center"
        }, [_vm._v(" " + _vm._s(_vm.$t("Actions")) + " ")])])])])];
      },
      proxy: true
    }, {
      key: "item.partName",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "d-flex"
        }, [item.reference === 'ORDER' ? [_c('v-tooltip', {
          staticClass: "pt-2",
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                  attrs = _ref6.attrs;
              return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('v-badge', {
                staticClass: "mr-3 pb-1",
                attrs: {
                  "dot": "",
                  "color": "orange"
                }
              })], 1)];
            }
          }], null, true)
        }, [_c('v-badge', {
          staticClass: "mr-3 pb-1",
          attrs: {
            "dot": "",
            "color": "orange"
          }
        }), _c('span', [_vm._v(_vm._s(_vm.$t('originBCMOrderTooltip')))])], 1)] : _vm._e(), _c('span', [_vm._v(_vm._s(item.partName))])], 2)];
      }
    }, {
      key: "item.deadline",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref8) {
              var on = _ref8.on;
              return [_c('v-icon', _vm._g({
                attrs: {
                  "color": _vm.getDeadlineColor(new Date(item.deadline))
                }
              }, on), [_vm._v(" fas fa-calendar-alt ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(_vm.getFormattedDeadline(new Date(item.deadline))))])])];
      }
    }, {
      key: "item.quantities",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('span', {
          staticClass: "font-weight-medium"
        }, [_c('span', [_vm._v(_vm._s(item.quantity - item.unlockedQuantity))]), _c('span', [_vm._v("/")]), _c('span', [_vm._v(_vm._s(item.unlockedQuantity))]), _c('span', [_vm._v("/")]), _c('span', [_vm._v(_vm._s(item.lockedQuantity))])])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('v-btn', {
          staticClass: "mr-2",
          attrs: {
            "color": "info",
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.initShowPartSettings(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "x-small": ""
          }
        }, [_vm._v(" fas fa-cog ")])], 1)];
      }
    }], null, true)
  })], 1) : _vm._e(), _c('v-col', {
    ref: 'displayOfJobs' + _vm.materialName,
    staticClass: "py-0 px-2 card-timeline d-flex",
    class: {
      'flex-wrap': _vm.expanded,
      'justify-space-around': _vm.expanded
    },
    attrs: {
      "cols": _vm.showPartsList ? 8 : 12,
      "color": "transparent"
    }
  }, _vm._l(_vm.jobs, function (job) {
    return _c('v-card', {
      key: job.renderKey,
      staticClass: "ma-4",
      attrs: {
        "min-width": "200px",
        "width": "200px",
        "height": "262px"
      },
      on: {
        "click": function click($event) {
          return _vm.clickJob(job);
        }
      }
    }, [_c('v-card-title', {
      staticClass: "pa-2 info"
    }, [_c('span', {
      staticClass: "white--text body-1"
    }, [_vm._v(_vm._s(job.jobName))]), _c('v-spacer'), job.isNew ? _c('span', {
      staticClass: "mr-2 orange--text body-1 text-new"
    }, [_vm._v(" " + _vm._s(_vm.$t("New")) + " ")]) : _vm._e(), job.locked ? _c('v-icon', {
      staticClass: "mr-1",
      attrs: {
        "small": "",
        "color": "orange"
      }
    }, [_vm._v(" fas fa-lock ")]) : _c('v-icon', {
      staticClass: "mr-1",
      attrs: {
        "small": "",
        "color": "success"
      }
    }, [_vm._v(" fas fa-lock-open ")])], 1), _c('v-card-text', {
      staticClass: "px-0 pb-0"
    }, [_c('v-row', {
      staticClass: "my-5 align-center",
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      staticClass: "py-0 text-center",
      attrs: {
        "cols": "3",
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-layout', {
      attrs: {
        "row": "",
        "wrap": "",
        "align-center": "",
        "justify-center": "",
        "fill-height": ""
      }
    }, [_c('v-img', {
      attrs: {
        "src": require("../../../../../public/img/icons/PlanningDark.png"),
        "alt": "planning dark",
        "max-width": "36",
        "max-height": "36",
        "width": "36",
        "height": "36"
      }
    })], 1)], 1), _c('v-col', {
      staticClass: "py-0 px-2 pl-3",
      attrs: {
        "cols": "9"
      }
    }, [_c('v-row', {
      staticClass: "mx-n2"
    }, [_c('div', {
      staticClass: "layout mb-1"
    }, [_c('span', {
      staticClass: "font-weight-bold mr-1"
    }, [_vm._v(" " + _vm._s(job.nbOfParts) + " ")]), job.nbOfParts > 1 ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("PlacedParts")) + " ")]) : _c('span', [_vm._v(_vm._s(_vm.$t("PlacedPart")))])])]), _c('v-row', {
      staticClass: "mx-n2"
    }, [_c('div', {
      staticClass: "layout mt-1"
    }, [_c('span', {
      staticClass: "font-weight-bold mr-1"
    }, [_vm._v(" " + _vm._s(job.nbOfLockedParts) + " ")]), job.nbOfLockedPart ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("AssignedParts")) + " ")]) : _c('span', [_vm._v(_vm._s(_vm.$t("AssignedPart")))])])])], 1)], 1), _c('v-divider'), _c('v-row', {
      staticClass: "mb-3 align-center",
      attrs: {
        "no-gutters": ""
      }
    }, [_c('div', {
      staticClass: "layout align-center justify-center"
    }, [_c('span', {
      staticClass: "heightCubeJobCard"
    }, [_vm._v(" " + _vm._s(job.nestingSize.height.toFixed(0)) + " ")]), _c('span', {
      staticClass: "widthCubeJobCard"
    }, [_vm._v(" " + _vm._s(job.nestingSize.width.toFixed(0)) + " ")]), _c('span', {
      staticClass: "depthCubeJobCard"
    }, [_vm._v(" " + _vm._s(job.nestingSize.depth.toFixed(0)) + " ")]), _c('v-img', {
      staticClass: "imageCube",
      attrs: {
        "src": require("../../../../../public/img/icons/cube_size_arrows-svg.svg"),
        "alt": "cube sizes",
        "max-width": "90px",
        "max-height": "90px"
      }
    })], 1)]), _c('v-divider'), _c('v-row', {
      staticClass: "ma-3 mt-2 align-center",
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-icon', {
      attrs: {
        "size": "30",
        "color": _vm.getDeadlineColor(job.minimumDeadline)
      }
    }, [_vm._v(" fas fa-calendar-alt ")])], 1), _c('v-col', {
      staticClass: "text-center"
    }, [_c('span', [_vm._v(_vm._s(_vm.getFormattedDeadline(job.minimumDeadline)))])])], 1)], 1)], 1);
  }), 1)], 1)], 1)], 1) : _vm._e(), _vm.lineData && _vm.lineDataTooLong && !_vm.expanded || _vm.expanded ? _c('v-card', {
    staticClass: "d-flex justify-center",
    attrs: {
      "flat": "",
      "color": "transparent",
      "width": "100%"
    }
  }, [_c('v-btn', {
    staticClass: "btn-expand elevation-4 menuBackground",
    on: {
      "click": function click($event) {
        _vm.expanded = !_vm.expanded;

        _vm.forceRerender();
      }
    }
  }, [!_vm.expanded ? _c('v-icon', {
    attrs: {
      "small": "",
      "color": "orange"
    }
  }, [_vm._v(" fas fa-chevron-down ")]) : _c('v-icon', {
    attrs: {
      "small": "",
      "color": "orange"
    }
  }, [_vm._v(" fas fa-chevron-up ")])], 1)], 1) : _vm._e(), _vm.jobSelected ? _c('JobDashboard', {
    attrs: {
      "job-metadata": _vm.jobSelected,
      "line-metadata": _vm.lineSelected,
      "orders-metadata": _vm.ordersMetadata
    }
  }) : _vm._e(), _c('v-dialog', {
    attrs: {
      "content-class": "module-PlanningLineDashboard",
      "max-width": "1400px",
      "min-height": "800px"
    },
    on: {
      "input": _vm.closeDialogPart
    },
    model: {
      value: _vm.modalPartSettings,
      callback: function callback($$v) {
        _vm.modalPartSettings = $$v;
      },
      expression: "modalPartSettings"
    }
  }, [_vm.currentPart && _vm.currentPartFileLoaded && _vm.modalPartSettings ? _c('PartAssignmentManager', {
    attrs: {
      "current-part": _vm.currentPart,
      "jobs": _vm.jobs,
      "parts": _vm.parts,
      "orders-metadata": _vm.ordersMetadata,
      "viewer-file-buffer": _vm.viewer3dFiles[_vm.currentPart.part],
      "supplier-u-u-i-d": _vm.$route.params.supplierUUID,
      "printer-brand": _vm.$route.params.printerBrand,
      "printer-model": _vm.$route.params.printerModel,
      "material-name": _vm.materialName,
      "job-number": -1
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }