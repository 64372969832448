<template lang="html" src="./archivesDashboard.template.vue"></template>

<style lang="scss" src="./archivesDashboard.scss"></style>

<script>
import { ApiErrorParser, EventBus } from '@cloudmanufacturingtechnologies/portal-components';
import JobDashboard from '../../jobDashboard/JobDashboard';

const i18nData = require('./archivesDashboard.i18n');

export default {
  name: 'ArchivesDashboard',
  components: {
    JobDashboard,
  },
  props: {
    printer: {
      type: Object,
      required: true,
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      formSortByItems: [],
      formSortBy: null,
      formMaterialsItems: [],
      formMaterials: [],
      formDateMin: null,
      formMenuDateMin: false,
      formDateMax: null,
      formMenuDateMax: false,
      archivedJobs: [],
      filteredJobs: [],
      lineSelected: false,
      jobSelected: false,
      ordersMetadata: null,
      jobsReloaded: false,
      currentSortAscending: true,
      lineData: {},
    };
  },
  created() {
    EventBus.$on('closeJobDashboard', () => {
      this.jobSelected = null;
    });
    EventBus.$on('reloadJob', this.reloadJob);
    this.formSortByItems = [
      this.$t('Material'),
      this.$t('JobNumber'),
      this.$t('ArchiveDate'),
    ];
    this.formSortBy = this.formSortByItems[0];
    this.getArchivedJobs();
  },
  beforeDestroy() {
    EventBus.$off('reloadJob', this.reloadJob);
  },
  mounted() {},
  methods: {
    getArchivedJobs() {
      this.archivedJobs = [];
      this.$apiInstance
        .getSupplierPrinterLinesArchivesMetadata(
          this.$route.params.supplierUUID,
          this.$route.params.printerBrand,
          this.$route.params.printerModel
        )
        .then(
          (data) => {
            for (const job of data.jobs) {
              const materialSet = new Set();
              if (!materialSet.has(job.material)) {
                materialSet.add(job.material);
                this.formMaterialsItems.push({
                  value: job.material,
                  text: this.$t(job.material),
                });
              }
              job.nbOfParts = 0;
              for (const productionPart of job.parts) {
                job.nbOfParts += productionPart.quantity;
              }
              job.key = job.material + '-' + job.number;
              job.last_modified = new Date(job.last_modified);
              job.status = 'DONE';
            }
            this.archivedJobs = data.jobs;

            this.lineData = {
              printer: this.printer,
            };
            this.ordersMetadata = data.ordersMetadata;
            this.jobsReloaded = true;
            this.updateFilteredJobs();
            this.sortFilteredJobs(this.currentSortAscending);
          },
          (error) => {
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        );
    },
    getFormattedDeadline(deadline) {
      if (deadline.getTime() === 0) {
        return ' - / - / - ';
      }
      return (
        deadline.getDate() +
        ' / ' +
        (deadline.getMonth() + 1) +
        ' / ' +
        deadline.getFullYear()
      );
    },
    async reloadJob(material, jobNumber) {
      while (!this.jobsReloaded) {
        await new Promise((resolve) => {
          setTimeout(resolve, 100);
        });
      }
      for (const job of this.archivedJobs) {
        if (job.number === jobNumber && job.material === material) {
          this.clickJob(job);
          break;
        }
      }
    },
    clickJob(job) {
      const line = {
        jobs: this.archivedJobs,
        parts: job.parts,
        lineData: this.lineData,
        material: job.material,
        printer: this.printer,
      };
      this.jobSelected = job;
      this.lineSelected = line;
      EventBus.$emit('updateJobParameters');

      setTimeout(() => {
        EventBus.$emit('printerLineDataReloaded');
      }, 0);
    },
    updateFilteredJobs() {
      this.filteredJobs = [];
      for (const job of this.archivedJobs) {
        if (
          (this.formMaterials.length === 0 ||
            this.formMaterials.includes(job.material)) &&
          (!this.formDateMin ||
            new Date(this.formDateMin) <= job.last_modified) &&
          (!this.formDateMax ||
            new Date(this.formDateMax).setDate(
              new Date(this.formDateMax).getDate() + 1
            ) >= job.last_modified)
        ) {
          this.filteredJobs.push(job);
        }
      }
      this.sortFilteredJobs();
    },
    reverseSort() {
      this.currentSortAscending = !this.currentSortAscending;
      this.sortFilteredJobs();
    },
    sortFilteredJobs() {
      if (this.formSortBy === this.$t('Material')) {
        this.filteredJobs.sort(this.compareJobsMaterial);
      } else if (this.formSortBy === this.$t('JobNumber')) {
        this.filteredJobs.sort(this.compareJobsNumber);
      } else if (this.formSortBy === this.$t('ArchiveDate')) {
        this.filteredJobs.sort(this.compareJobsArchiveDate);
      }
      if (!this.currentSortAscending) {
        this.filteredJobs.reverse();
      }
    },
    compareJobsArchiveDate(j1, j2) {
      if (j1.last_modified > j2.last_modified) {
        return 1;
      } else if (j2.last_modified > j1.last_modified) {
        return -1;
      }
      return 0;
    },
    compareJobsMaterial(j1, j2) {
      if (j1.material > j2.material) {
        return 1;
      } else if (j2.material > j1.material) {
        return -1;
      }
      // Materials are equal, second sort (by number)
      if (j1.number > j2.number) {
        return 1;
      } else if (j2.number > j1.number) {
        return -1;
      }
      return 0;
    },
    compareJobsNumber(j1, j2) {
      if (j1.number > j2.number) {
        return 1;
      } else if (j2.number > j1.number) {
        return -1;
      }
      // Numbers are equal, second sort (by material)
      if (j1.material > j2.material) {
        return 1;
      } else if (j2.material > j1.material) {
        return -1;
      }
      return 0;
    },
  },
};
</script>
